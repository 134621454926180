import React, { useState, useContext, useEffect, useCallback } from "react";
import ModalUI from "../../../UI/ModalUI/ModalUI";
import { Row, Col } from "react-bootstrap";
import Azure1 from "../../../../../images/Azure1.svg";
import Azure2 from "../../../../../images/Azure2.svg";
import awsImg from "../../../../../images/aws.svg";
import googleCloud from "../../../../../images/GoogleCloud.svg";
import progress from "../../../../../images/StateProgress.svg";
import completed from "../../../../../images/StateCompleted.svg";
import failed from "../../../../../images/StateFailed.svg";
import disconnect from "../../../../../images/disconnect.svg";
import reset from "../../../../../images/reset.svg";
import context from "../../../../../store/create-context";
import styles from "../AddConnectCloudModal/AddConnectCloudModal.module.css";
import correct from "../../../../../images/correct.svg";
import WebSocketInstance from "../../../../../common/WebSocketInstance";
import commonStyles from '../../../../../common/styles/common.module.css'; // Import common styles
import "bootstrap/dist/css/bootstrap.min.css";

const AddConnectCloudModal = (props) => {
  const ctx = useContext(context);
  const [mode, setMode] = useState("addNew");
  const [arnRole, setArnRole] = useState("");
  const [allArnRoles, setAllArnRoles] = useState([]);
  const [selectedServer, setSelectedServer] = useState();
  const [awsAccountId, setAwsAccountId] = useState("");
  const [externalId, setExternalId] = useState("");
  const [pastExternalIds, setPastExternalIds] = useState("");
  const [invalidArnRole, setInvalidArnRole] = useState(false);
  const [arnStatusMap, setArnStatusMap] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isDemoAccountSelected, setIsDemoAccountSelected] = useState(false);
  const [toastClosed, setToastClosed] = useState(false);
  const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "dev";
  const socket = WebSocketInstance.getInstance();

  const jokes = [
    "Why did the AI cross the road? To optimize the chicken's path!",
    "Why don't robots have brothers? Because they all share the same bits!",
    "Why was the computer cold? It left its Windows open!",
    "Why did the robot go on a diet? It had too many bytes!",
    "Why did the AI go to school? To improve its neural networks!",
    "Why did the cloud break up with the data center? It found someone more scalable!",
    "Why do cloud servers never get lost? Because they always follow the protocol!",
    "Why did the developer go broke? Because he used up all his cache!",
    "Why don't clouds need a suitcase? Because they travel light!",
    "Why did the cloud apply for a job? It wanted to be part of the network!",
    "Why did the computer keep sneezing? It had a virus!",
    "Why did the programmer go broke? Because he couldn't find his cache!",
    "Why did the computer go to the doctor? Because it had a bad case of bugs!",
    "Why did the developer go broke? Because he used up all his resources!",
    "Why did the computer sit on the clock? To keep up with the times!",
    "Why did the computer go to art school? It wanted to improve its graphics!",
    "Why did the server go to therapy? It had too many connections!",
    "Why did the computer go to the beach? To surf the net!",
    "Why did the computer go to the bar? To get a byte!",
    "Why did the computer go to the library? To get its bytes checked out!",
  ];
   
  const puzzles = [
    "What has keys but can't open locks?",
    "What has a head, a tail, is brown, and has no legs?",
    "Can you name three consecutive days without using the words Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, or Sunday?",
    "I speak without a mouth and hear without ears. I have no body, but I come alive with wind. What am I?",
    "You measure my life in hours and I serve you by expiring. I'm quick when I'm thin and slow when I'm fat. The wind is my enemy. What am I?",
    "What comes once in a minute, twice in a moment, but never in a thousand years?",
    "I have cities, but no houses. I have mountains, but no trees. I have water, but no fish. What am I?",
    "What can travel around the world while staying in a corner?",
    "What has many keys but can't open a single lock?",
    "What is so fragile that saying its name breaks it?",
  ];

  useEffect(()=>{
    return ()=>{
      if (socket?.socketRef) {
      socket.socketRef.removeAllListeners('cloud_aws_resolve');
      }
    }
  },[props.show, mode, socket.socketRef])

  const modalCloseHandler = () => {
    props.handleClose();
    setPastExternalIds("");
    setExternalId("");
    setAwsAccountId("");
    setErrorMessage("");
    setSelectedServer();
    setArnRole("");
    setIsDemoAccountSelected(false)
  };

  const toggleMode = (newMode) => {
    setMode(newMode);
  };

  const handleChange = (e) => {
    e.stopPropagation();
    setArnRole(e.target.value);
  };

  const handleAccountIdChange = (e) => {
    setAwsAccountId(e.target.value);
  };

  const isValidAccountId = (accountId) => {
    const accountIdRegex = /^\d{12}$/;
    return accountIdRegex.test(accountId);
  };

  const isValidArnRole = (arnRole) => {
    const arnRegex = /^arn:(.*):iam:(.*)$/;
    return arnRegex.test(arnRole);
  };

  const handleExternalIdResponse = (response) => {
    setPastExternalIds("");
    setErrorMessage("");
    if (response?.success) {
      if (response.external_id !== null && response.external_id !== undefined && response.external_id !== "") {
        setExternalId(response.account_id + ": " + response.external_id);
      } else {
        setErrorMessage("Failed to fetch External ID. Please retry!");
      }
    } else {
      // setExternalId("Failed to fetch External ID. Please retry!");
      setErrorMessage(response.error);
    }
  };

  const handlePastExternalIdsResponse = (response) => {
    if (response?.success) {
      if (response.past_external_ids && Object.keys(response.past_external_ids).length > 0) {
        setPastExternalIds(response.past_external_ids);
        setExternalId("");
        setErrorMessage("");
      } else {
        setErrorMessage("It looks like you don't have any previously created External IDs in this project.");
      }
    } else {
      setErrorMessage(response.error);
    }
  };

  const handleArnStatusResponse = (response) => {
    if (response?.success) {
      if (response.iam_role_arn_with_status_map !== null) {
        setArnStatusMap(response.iam_role_arn_with_status_map);
      } else {
        setArnStatusMap(["Uh oh! It looks like you have not connected any cloud account yet."]);
      }
    } else {
      setArnStatusMap(["Failed to get the list of connected cloud accounts. Please retry!"]);
    }
  };

  function handleCloudAwsConnect(response) {
    if (response?.success === false) {
      ctx.setShowToastr({
        show: true,
        type: "error",
        text: response?.error,
      });
    }
  }

  function sendCloudAwsResolveQuery(socket, awsAccountId) {
    socket.send("cloud_aws_resolve", {
      account_id: awsAccountId,
      project_id: sessionStorage.getItem("jml_workspace_id"),
      operation: "query_acc_id_and_ext_id"
    });
    socket.addEventListener("cloud_aws_resolve", handleExternalIdResponse);
  }
    
  const getAwsExtIdFromBackend = () => {
    if (isDemoAccountSelected || isValidAccountId(awsAccountId)) {
      sendCloudAwsResolveQuery(socket, awsAccountId);
    } else {
      setErrorMessage("Please provide a valid AWS account ID.");
      setExternalId("");
    };
  };

  const showPastExternalIds = () => {    
    setAwsAccountId("");
    socket.send("cloud_aws_resolve", {
      project_id: sessionStorage.getItem("jml_workspace_id"),
      operation: "query_acc_id_and_ext_id_map"
    });
    socket.addEventListener("cloud_aws_resolve", handlePastExternalIdsResponse);

  };

  const giveAccess = () => {
    if (!isValidArnRole(arnRole)) {
      setInvalidArnRole(true);
      return;
    } else {
      setInvalidArnRole(false);
      setAllArnRoles((prev) => {
        return [...prev, { id: Date.now(), role: arnRole, server: selectedServer }];
      });
      setArnRole("");
      isDevEnvironment && console.log("Sending allArnRoles", allArnRoles);
      linkSvgClickHandler();
    }
    modalCloseHandler()
  };

  const linkSvgClickHandler = () => {
    if (arnRole !== "" && selectedServer === awsImg) {
      cloudClickHandler(
        ctx,
        arnRole,
        props.setCheckActivity,
        props.setPendingActivity,
        setArnRole,
        props.togglePlusDropdown
      );
    }
  };

  useEffect(() => {
    const intervalDuration = 1000;
    const start_next_joke_at = 30;
    const start_puzzle_at = 60;
    const apologies_at = 90;
    const randomJoke = jokes[Math.floor(Math.random() * jokes.length)];
    const randomJoke2 = jokes[Math.floor(Math.random() * jokes.length)];
    const randomPuzzle = puzzles[Math.floor(Math.random() * puzzles.length)];
    const randomJoke3 = jokes[Math.floor(Math.random() * jokes.length)];

    let timer = start_puzzle_at;
    let elapsedTime = 0;

    const interval = setInterval(() => {
        timer -= 1;
        elapsedTime += 1;
        const paddedTimer = timer < 10 ? ` ${timer}` : `${timer}`; // Note the extra space before the timer
        if(toastClosed){
        if (elapsedTime < start_next_joke_at) {
          ctx.setShowToastr({
            show: true,
            type: "info",
            text: (
              <>
                Connecting to your account {awsAccountId}. We will wrap up the boring work in: <span style={{ fontFamily: 'monospace' }}>{paddedTimer}</span>{" "} seconds. 
                <br/><br/>Here's something funny while u wait: {randomJoke}
              </>
            )        
          });
        } else if (elapsedTime < start_puzzle_at) {
          ctx.setShowToastr({
            show: true,
            type: "info",
            text: (
              <>
                Connecting to your account {awsAccountId}. We will wrap up the boring work in: <span style={{ fontFamily: 'monospace' }}>{paddedTimer}</span>{" "} seconds. 
                <br/><br/>Here's another one: <br/>{randomJoke2}
              </>
              )
          });
        } else if (elapsedTime < apologies_at) {
          ctx.setShowToastr({
            show: true,
            type: "info",
            text: (
              <>
                This is taking a while. Could mean that our servers are taking a lot of traffic. Hold tight! <br/><br/>Can you solve this puzzle in the meantime: {randomPuzzle}
              </>
              )
            });
        } else if (elapsedTime >= apologies_at) {
          ctx.setShowToastr({
            show: true,
            type: "info",
            text: (
              <>
                Omg. This is embarrasing. Someone has been paged! <br/><br/>Meanwhile here is something else to make you hate us less: {randomJoke3}
              </>
            )
          });
        }
        }
    }, intervalDuration);

    if (!props.checkCloudActivity) {
      clearInterval(interval);
      ctx.setShowToastr({ show: false });
    }
    return () => clearInterval(interval);
  }, [props.checkCloudActivity])

  function cloudClickHandler(
    ctx,
    arnRole,
    setCheckActivity,
    setPendingActivity,
    setArnRole
  ) {
    socket.send("cloud_aws_connect", {
      role: arnRole,
      project_id: sessionStorage.getItem("jml_workspace_id"),
      operation: "connect",
      use_cache: ctx.useCache,
    });
    socket.addEventListener("cloud_aws_connect", handleCloudAwsConnect);

    setCheckActivity(true);
    props.setCheckCloudActivity(true);
    setPendingActivity(true);
    setToastClosed(true);
    setArnRole("");
    setSelectedServer();
    setArnRole("");
    // togglePlusDropdown && togglePlusDropdown();
  }

  const showArnStatus = useCallback(() => {
    socket.send("cloud_aws_resolve", {
      project_id: sessionStorage.getItem("jml_workspace_id"),
      operation: "query_iam_role_arn_with_status_map"
    });
    socket.addEventListener("cloud_aws_resolve", handleArnStatusResponse);
  }, [socket]);

  useEffect(() => {
    if (mode === "Connected") {
      const cleanupShowArnStatus = showArnStatus();
      return cleanupShowArnStatus;
    }
  }, [mode, showArnStatus]);

  useEffect(() => {
    if (isDemoAccountSelected) {
      getAwsExtIdFromBackend();
      setIsDemoAccountSelected(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDemoAccountSelected]);

  const serverlessPDFAccount = () => {
    setSelectedServer(awsImg);
    setAwsAccountId('692859938530');
    setArnRole("arn:aws:iam::692859938530:role/JigsawMLMagic");
    setIsDemoAccountSelected(true);
  };
    
  const retailDemoStoreAccount = () => {
    setSelectedServer(awsImg);
    setAwsAccountId('241533118763');
    setArnRole("arn:aws:iam::241533118763:role/JigsawMLSolvesUrCloudPuzzle");
    setIsDemoAccountSelected(true);
  };

  const serverlessEspressoAccount = () => {
    setSelectedServer(awsImg);
    setAwsAccountId('221082206129');
    setArnRole("arn:aws:iam::221082206129:role/JigsawMLFTW");
    setIsDemoAccountSelected(true);
  };

  const autoFillMediaReplayEngineAccount = () => {
    setSelectedServer(awsImg);
    setAwsAccountId('194722446719');
    setArnRole("arn:aws:iam::194722446719:role/jmltest");
    setIsDemoAccountSelected(true);
  };

  const autoFillPythonIOTAccount = () => {
    setSelectedServer(awsImg);
    setAwsAccountId('021891593773');
    setArnRole("arn:aws:iam::021891593773:role/jmltest");
    setIsDemoAccountSelected(true);
  };

  const getAwsAccountClass = (accountId) => {
    switch (accountId) {
      case "241533118763":
        return "RetailDemoStore";
      case "692859938530":
        return "ServerlessPDFChat";
      case "221082206129":
        return "ServerlessEspresso";
      case "194722446719":
        return "MediaReplayEngineApp";
      case "021891593773":
        return "JavaScriptIOTApp";
      default:
        return "";
    }
  };

  return (
    <div>
      <ModalUI
        show={props.show}
        handleClose={modalCloseHandler}
        theme="dark"
        classes="border-no pb-0 m-0"
        modalTitle="Link Cloud Accounts"
        size="md"
      >
        <div className={styles.model_container}>
          <Row className={styles.custom_button_container}>
            <Col>
              <p className={styles.heading}>
                You can add multiple cloud accounts. {" "}
                <a href="https://jigsawml.com/cloud-scanner-userguide" target="_blank" rel="noopener noreferrer">User Guide</a>
              </p>
              <div className={styles.button_container}>
                <ul
                  className={`nav nav-tabs ${styles.nav_tabs}`}
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        mode === "addNew" ? styles.active_nav_link : styles.nav_links
                      } ${styles.nav__links}`}
                      type="button"
                      role="tab"
                      aria-controls="home"
                      onClick={() => toggleMode("addNew")}
                      style={{
                        marginLeft: "2rem",
                        borderTop: mode === "addNew" ? "1px solid #4A4A4A" : "none",
                        borderLeft: mode === "addNew" ? "1px solid #4A4A4A" : "none",
                        borderRight: mode === "addNew" ? "1px solid #4A4A4A" : "none",
                        borderBottom: "1px solid #1e1e1e",
                        backgroundColor: mode === "addNew" ? "#1e1e1e" : "#1e1e1e",
                        color: mode === "addNew" ? "#FFFFFF" : "#FFFFFF",
                        opacity: mode === "addNew" ? "100%" : "50%",
                      }}
                    >
                      Add new
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        mode === "Connected" ? styles.active_nav_link : styles.nav_links
                      } ${styles.nav__links}`}
                      data-bs-toggle="tab"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      onClick={() => toggleMode("Connected")}
                      style={{
                        borderTop: mode === "Connected" ? "1px solid #4A4A4A" : "none",
                        borderLeft: mode === "Connected" ? "1px solid #4A4A4A" : "none",
                        borderRight: mode === "Connected" ? "1px solid #4A4A4A" : "none",
                        borderBottom: "1px solid #1e1e1e",
                        backgroundColor: mode === "Connected" ? "#1e1e1e" : "#1e1e1e",
                        color: mode === "Connected" ? "#FFFFFF" : "#FFFFFF",
                        opacity: mode === "Connected" ? "100%" : "50%",
                      }}
                    >
                      Connected
                    </button>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          {mode === "addNew" && (
            <div className={styles.server_main_container}>
                <div className={`mt-4 ${styles.server_container}`}>
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className={`opacity-50 ${styles.heading_synced}`}>
                        Please select your cloud platform
                      </p>
                      <div className={`dropdown ${styles.auto_fill_dropdown}`}>
                        <button
                            className={`btn btn-secondary dropdown-toggle ${commonStyles.auto_fill_button}`}
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => {
                              setExternalId("");
                              setAwsAccountId("");
                              setSelectedServer();
                              setArnRole("");
                            }}
                        >
                          Try a Demo Account
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <li>
                            <button className="dropdown-item" onClick={retailDemoStoreAccount}  title="https://github.com/aws-samples/retail-demo-store">
                              Retail Demo Store
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item" onClick={serverlessPDFAccount}  title="https://github.com/aws-samples/serverless-pdf-chat">
                              Serverless PDF Chat
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item" onClick={serverlessEspressoAccount}  title="https://github.com/aws-samples/serverless-coffee">
                              Serverless Espresso
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item" onClick={autoFillMediaReplayEngineAccount}  title="https://github.com/awslabs/aws-media-replay-engine">
                              MediaReplay Engine App
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item" onClick={autoFillPythonIOTAccount} title="https://github.com/aws-samples/aws-appsync-iot-core-realtime-dashboard">
                              JavaScript IOT App
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className={`d-flex justify-content-around ${styles.cloud_buttons}`}>
                        <button onClick={() => setSelectedServer(Azure1)} disabled className={styles["disable-img"]}>
                          <img src={Azure1} alt="Azure1" />
                          <img src={Azure2} alt="Azure2" />
                        </button>
                        <button onClick={() => setSelectedServer(awsImg)}>
                          <img src={awsImg} alt="awsImg" />
                        </button>
                        <button onClick={() => setSelectedServer(googleCloud)} disabled className={styles["disable-img"]}>
                          <img src={googleCloud} alt="googleCloud" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {selectedServer === googleCloud && (
                    <div>
                      <p style={{ textAlign: "center" }}>Google Cloud Platform will be supported soon!</p>
                    </div>
                  )}
                  {selectedServer === Azure1 && (
                    <div>
                      <p style={{ textAlign: "center" }}>Microsoft Azure will be supported soon!</p>
                    </div>
                  )}
                {selectedServer === awsImg && (
                  <div className={styles.instructions_container}>
                    <div className={`px-4 py-3 ${styles["f-12"]}`}>
                      Instructions (if not using the demo account):
                      <ul className="mb-0 mt-2">
                        <li className={`${styles["f-12"]}`}>
                          Type your AWS Account ID and click "Get External ID"
                        </li>
                        <li className={`${styles["f-12"]}`}>
                          Use this External ID to create an IAM role with AWS with read-only permissions.
                        </li>
                        <li className={`${styles["f-12"]}`}>
                          After creating the IAM role, come back to this screen, provide the role ARN and click "Give Access".
                        </li>
                        <li className={`${styles["f-12"]}`}>
                          Wait for a few minutes and refresh if needed.
                        </li>
                        <li className={`${styles["f-12"]}`}>
                          A detailed step-by-step guide can be found <a href="https://jigsawml.com/cloud-scanner-userguide" target="_blank" rel="noopener noreferrer">here</a>.
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                  {selectedServer === awsImg && (
                    <>
                    <br/>
                      <div className={styles.externalID_container}>
                        <div className={styles.externalID_input_container}>
                          <button
                            style={{ display: "block", whiteSpace: "pre-wrap" }}
                            onClick={showPastExternalIds}
                            className={`mb-3 ${styles.showPastExternalIds_button}`}
                          >
                            <span style={{ fontSize: "14px" }}>Show List of Account IDs and corresponding External IDs</span> <br/>
                            <span style={{ fontSize: "8px" }}>You might have previously created an IAM role for one of the IDs from the list</span>
                          </button>
                          <input
                            type="text"
                            placeholder="Enter Account ID"
                            value={awsAccountId}
                            onChange={(e) => handleAccountIdChange(e)}
                            className={`mb-3 ${styles.awsAccountId_inputbox}`}
                          />
                          <button
                            onClick={getAwsExtIdFromBackend}
                            className={`mb-3 ${styles.getExternalId_button}`}
                          >
                            Get External ID
                          </button>
                        </div>
                        <div className={styles.externalID_output_container}>
                          {externalId && (externalId !== "undefined") && (
                            <p style={{ whiteSpace: 'nowrap', textAlign: 'left', fontSize: '12px' }}>{externalId}</p>
                          )}
                          {Object.values(pastExternalIds).length > 0 && !errorMessage &&(
                            <div className={styles.externalIdTable_container}>
                              <table className={styles.externalIdTable}>
                                <thead>
                                  <tr>
                                    <th className={styles.externalIdTable_header}>Account ID</th>
                                    <th className={styles.externalIdTable_header}>External ID</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(pastExternalIds).map(([pastAccountId, pastExternalId], index) => (
                                    <tr key={index}>
                                      <td style={{ whiteSpace: 'nowrap' }}>{pastAccountId}</td>
                                      <td style={{ whiteSpace: 'nowrap' }}>{pastExternalId}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                          {errorMessage && (
                            <div>
                              <span className={styles.error_messages}>{errorMessage}</span>
                            </div>
                          )}
                        </div>
                      </div>
                      {(externalId || Object.values(pastExternalIds).length > 0) && !errorMessage && (
                        <>
                          <div className={styles.arnRole_container}>
                            <input
                              type="text"
                              placeholder="Enter the IAM role ARN you created"
                              value={arnRole}
                              onChange={(e) => handleChange(e)}
                              className={` mb-3 ${styles.arnRole_inputbox}`}
                            />
                            {invalidArnRole && (
                              <p className={styles.error_message}>Please provide a valid IAM role ARN</p>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              <div className={styles.giveAccess_container}>
                {selectedServer === awsImg && (
                  <button
                    className={`${styles.giveAccess_button} ${(arnRole && selectedServer && externalId !== "") && styles.giveAccess_button_highlight}
                    ${getAwsAccountClass(awsAccountId)}`}
                    onClick={() => giveAccess()}
                  >
                    <img src={correct} alt="correct" />
                    Give Access
                  </button>
                )}
              </div>
            </div>
          )}
          {mode === "Connected" && (
            <div>
              <p className={`mt-4 ${styles.heading_synced}`}>Previously connected cloud accounts</p>
              <div className={`mt-4 ${styles.synced_container}`}>
                <ul>
                  {arnStatusMap && Object.entries(arnStatusMap).length > 0 && Object.entries(arnStatusMap).map(([arn, status], index) => (
                    <li key={index} className={styles.synced_list}>
                      <img className={styles.cloud_platform_image} src={awsImg} alt="aws" />
                      <span className={styles.text}>{arn}</span>
                      <img className={styles.reset_image} src={reset} alt="reset" />
                      <img className={styles.disconnect_image} src={disconnect} alt="disconnect" />
                      {(status === "COMPLETED" || status === "DONE" || status === "SUCCEEDED") && (
                        <img className={styles.status_image} src={completed} alt="completed" />
                      )}
                      {status === "RUNNING" && (
                        <img className={styles.status_image} src={progress} alt="progress" />
                      )}
                      {status === "FAILED" && (
                        <img className={styles.status_image} src={failed} alt="failed" />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </ModalUI>
    </div>
  );
};

export default AddConnectCloudModal;
