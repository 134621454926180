import React, { useState, useRef } from "react";
import ModalUI from "../../components/UI/ModalUI/ModalUI";
import { Row, Col, Form } from "react-bootstrap";
import styles from "./FeedbackButton.module.css";
import starReview from "../../../images/starReview.svg";

const FeedbackButton = () => {
  const [showModal, setShowModal] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formRef = useRef(null); // Create a ref for the form
  const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "dev";

  const handleShow = () => {
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData(formRef.current); // Get form data using FormData API

    // Validate email before submission
    const email = formData.get("Email");
    if (!email || !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email)) {
      setIsValidEmail(false);
      setEmailError("Please enter a valid email address.");
      setIsSubmitting(false);
      return;
    }

    // Convert FormData to a plain object
    let requestBody = new FormData(formRef.current);

    try {
      const feedbackScriptURL = 'https://script.google.com/macros/s/AKfycbwjK52uxlenZq-tSjjNf4Sz1Il4TpmeqF231p63YwR6QFQYguuhvjaoMGqNacGv-sM7JA/exec'; // Replace with your Apps Script URL

      const response = await fetch(feedbackScriptURL, {
        method: "POST", // POST method
        body: requestBody, // Send the FormData object
        mode: "no-cors", // No CORS mode to bypass restrictions
      });

      // Since we're in 'no-cors' mode, we can't inspect the response body
      // but we can log the status and any other information from the request
      if (response.ok) {
        isDevEnvironment && console.log("Feedback successfully submitted!");
      } else {
        isDevEnvironment && console.error("Error submitting feedback.");
      }
    } catch (error) {
        isDevEnvironment && console.error("Error connecting to the Google Apps Script:", error);
    }

    // Add a delay of 2 seconds before closing the modal
    setTimeout(() => {
      handleClose();
      setIsSubmitting(false);
    }, 2000);
  };

  return (
    <div>
      <button
        className={`${styles["feedback-btn"]}`}
        onClick={handleShow}
      >
        <span><img src={starReview} alt="Feedback" className={`${styles["feedback-btn-img"]}`} /></span>
        <span>Feedback</span>
      </button>

      <ModalUI
        show={showModal}
        handleClose={handleClose}
        modalTitle=""
        theme="light"
        classes="border-no"
        size="md"
      >
        <Row>
          <Col>
            <div className={`px-4 ${styles["feedback-container"]}`}>
              <h3 className="text-black m-auto mb-2 text-center">
                Thank you for using JigsawML!
              </h3>
              <p className="text-black opacity-50 m-auto mb-0 text-center">
                Your experience matters to us, and we'd love to hear your thoughts!
              </p>
              <form ref={formRef} onSubmit={handleSubmit}>
                <div className={`${styles["feedback-group"]}`}>
                  <Form.Group className={`${styles["feedback-input"]}`}>
                    {/* Name Input */}
                    <Form.Control
                      name="Name"
                      placeholder="👤 Your Full Name"
                      className={`${styles["custom-input"]}`}
                      required
                    />
                    {/* Email Input */}
                    <Form.Control
                      type="email"
                      name="Email"
                      placeholder="✉️ Your Email Address"
                      className={`${styles["custom-input"]} ${
                        !isValidEmail && styles["error"]
                      }`}
                      required
                    />
                    {/* Message Input */}
                    <Form.Control
                      as="textarea"
                      name="Message"
                      placeholder="💬 Share your suggestions, feedback, or issues, and let us know which features or components stood out to you!"
                      className={`${styles["message-input"]}`}
                      rows={3}
                      required
                    />
                    <p className={styles["error-text"]}>
                      {!isValidEmail && emailError}
                    </p>
                  </Form.Group>
                  {/* Submit Button */}
                  <button
                    className={`${styles["btn"]} ${styles["submit-feedback-btn"]}`}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "⏳" : "Submit Feedback"}
                  </button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </ModalUI>
    </div>
  );
};

export default React.memo(FeedbackButton);
