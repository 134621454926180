import { useState } from "react";
import crossImg from "../../images/crossBtn.svg";
import menuImg from "../../images/menu.svg";
import styles from "./navbar.module.css";
import { NewLogoSvg } from "../../images/svg";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Navbar = ({
  setEmail,
  setName,
  setAffiliation,
  setMessage,
  setEmailError,
  setShowAccessModal,
  setIsValidEmail,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleProductToggle = (isOpen) => {
    setIsProductDropdownOpen(isOpen);
  };

  return (
    <div className="container-fluid">
      <nav className="navbar navbar-expand-sm py-4">
        <div className="container-fluid">
          <a
            className={`navbar-brand ${styles["navbar-brand-wrapper"]}`}
            href={`${window.location.origin}`}
          >
            <NewLogoSvg />
          </a>

          {/* Menu Toggle (Hamburger Icon) */}
          <div className={styles["menu-toggle"]}>
            <img
              src={menuImg}
              alt="menu"
              className={styles["menu-icon"]}
              onClick={toggleMenu}
            />
          </div>

          {/* Menu Content */}
          {isMenuOpen && (
            <div
              className={`${styles["menu-content"]} ${
                isMenuOpen ? styles["show"] : ""
              }`}
            >
              <div className={styles["menu-close"]} onClick={toggleMenu}>
                <img
                  src={crossImg}
                  alt="crossBtn"
                  className={styles["cross-icon"]}
                />
              </div>
              <div className={styles["nav-items"]}>
                <div className={styles["nav-item"]}>
                  <Dropdown>
                    <Dropdown.Toggle style={{background:'transparent', border:'none'}}>
                      <span className={styles["nav-link"]} style={{marginLeft:'23px'}}>
                        Product
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{border:'none', marginLeft:'40px'}}>
                      <Dropdown.Item as={Link} to="/product">
                        SW Architecture Visualizer
                        <span className={styles["beta-badge"]}>Beta</span>
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/cloud-scanner">
                        Cloud Scanner
                        <span className={styles["beta-badge"]}>Free trial</span>
                        {/* <span className={styles["beta-badge"]}>Coming Soon</span> */}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <Link to="/blogs" className={styles["nav-link"]}>
                  Blogs
                </Link>
                <Link to="/in-press" className={styles["nav-link"]}>
                  Press
                </Link>
              </div>
            </div>
          )}

          {/* Desktop View Nav Links */}
          <div className={styles["nav-middle-container"]}>
            <div className={styles["nav-item"]}>
              <Dropdown onToggle={handleProductToggle}>
                <Dropdown.Toggle style={{background:'transparent', border:'none'}}>
                  <span className={styles["nav-link"]}>
                    Product
                    {isProductDropdownOpen ? <FaChevronUp className={styles["chevron-icon"]} /> : <FaChevronDown className={styles["chevron-icon"]} />}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{border:'none'}}>
                  <Dropdown.Item as={Link} to="/product">
                    SW Architecture Visualizer
                    <span className={styles["beta-badge"]}>In Beta</span>
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/cloud-scanner">
                    Cloud Scanner
                    <span className={styles["beta-badge"]}>Free trial</span>
                    {/* <span className={styles["beta-badge"]}>Coming Soon</span> */}
                    </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Link to="/blogs" className={styles["nav-link"]}>
              Blogs
            </Link>
            <Link to="/in-press" className={styles["nav-link"]}>
              Press
            </Link>
          </div>

          {/* Sign-up Button */}
          <button
            className={`${styles["btn"]}`}
            type="button"
            onClick={() => {
              setEmail("");
              setName("");
              setAffiliation("");
              setMessage("");
              setIsValidEmail(null);
              setEmailError(false);
              setShowAccessModal(true);
            }}
          >
            Sign up for Beta
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
