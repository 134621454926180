import React, { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

function RedriveIndexingPage({ onLogin, redirectTo }) {
  const [adminUsername, setAdminUsername] = useState("");
  const [adminPassword, setAdminPassword] = useState("");

  const [uEmail, setUEmail] = useState("");
  const [projectId, setProjectId] = useState("");
  const [activityId, setActivityId] = useState("");

  const { state } = useLocation();
  const navigate = useNavigate();
  const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "dev";

  async function handleSubmit(event) {
    event.preventDefault();
    isDevEnvironment && console.log("Admin", adminUsername, " trying to redrive indexing for user", uEmail);
    const isAuthenticated = await onLogin(adminUsername, adminPassword, uEmail, projectId, activityId);
    if (isAuthenticated) {
      isDevEnvironment && console.log("Authentication succeeded. Redirecting to",redirectTo)
      navigate(state?.path || "/");
    }
    else {
      // handle login failure
      isDevEnvironment && console.log("Admin login failure");
    }
  }

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5"
  };

  const formStyle = {
    display: "flex",
    flexDirection: "column",
    width: "300px",
    gap: "10px"
  };

  const inputStyle = {
    padding: "10px",
    fontSize: "18px",
    borderRadius: "5px",
    border: "1px solid #ddd"
  };

  const buttonStyle = {
    padding: "10px",
    fontSize: "18px",
    borderRadius: "5px",
    border: "none",
    color: "#fff",
    backgroundColor: "#007BFF",
    cursor: "pointer"
  };

  return (
    <div style={containerStyle}>
      <form onSubmit={handleSubmit} style={formStyle}>
        <input
          type="text"
          value={adminUsername}
          onChange={e => setAdminUsername(e.target.value)}
          placeholder="Admin Username"
          style={inputStyle}
        />
        <input
          type="password"
          value={adminPassword}
          onChange={e => setAdminPassword(e.target.value)}
          placeholder="Admin Password"
          style={inputStyle}
        />
        <br/>
        Enter User Details
        <input
            type="text"
            value={uEmail}
            onChange={e => setUEmail(e.target.value)}
            placeholder="Email"
            style={inputStyle}
        />
        <input
            type="text"
            value={projectId}
            onChange={e => setProjectId(e.target.value)}
            placeholder="Project Id"
            style={inputStyle}
        />
        <input
            type="text"
            value={activityId}
            onChange={e => setActivityId(e.target.value)}
            placeholder="Activity Id"
            style={inputStyle}
        />
        <button type="submit" style={buttonStyle}>Redrive Indexing</button>
      </form>
    </div>
  );
}

export default RedriveIndexingPage;
