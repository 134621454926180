import React, { useState, useRef } from "react";
import styles from "./BetaButtonForScanner.module.css";
import AccessModal from "../../NewLanding2/AccessModal";
import ThanksModal from "../../NewLanding2/ThanksModal";
import useFormHandler from "../../NewLanding2/useFormHandler";
import handhsake from "../../../images/handshake.svg";
import starReview from "../../../images/starReview.svg";

const BetaButtonForScanner = () => {
    const {
        email,
        name,
        affiliation,
        message,
        isValidEmail,
        emailError,
        showAccessModal,
        showThanksModal,
        isSubmitting,
        formRef,
        setEmailHandler,
        setNameHandler,
        setAffiliationHandler,
        setMessageHandler,
        formSubmitHandler,
        setEmail,
        setName,
        setAffiliation,
        setMessage,
        setIsValidEmail,
        setEmailError,
        setShowAccessModal,
        setShowThanksModal,
      } = useFormHandler();

  return (
    <>
        <button
        className={styles["beta-button"]}
        type="button"
        onClick={() => {
            setEmail("");
            setName("");
            setAffiliation("");
            setMessage("");
            setIsValidEmail(null);
            setEmailError(false);
            setShowAccessModal(true);
        }}
        >
        <span><img src={handhsake} alt="Join Beta" className={`${styles["beta-btn-img"]}`} /></span>
        <span>Join Beta</span>
        </button>
        <AccessModal
        show={showAccessModal}
        handleClose={() => setShowAccessModal(false)}
        formRef={formRef}
        name={name}
        affiliation={affiliation}
        message={message}
        email={email}
        isValidEmail={isValidEmail}
        emailError={emailError}
        isSubmitting={isSubmitting}
        setNameHandler={setNameHandler}
        setAffiliationHandler={setAffiliationHandler}
        setMessageHandler={setMessageHandler}
        setEmailHandler={setEmailHandler}
        formSubmitHandler={formSubmitHandler}
      />
      <ThanksModal
        show={showThanksModal}
        handleClose={() => setShowThanksModal(false)}
    />
    </>
  );
};

export default BetaButtonForScanner;