import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import "./InfraStructure.css";
import azIcon from "../../../images/AZIcon.svg";
import downArrow from "../../../images/downVector.svg";
import {removeDuplicates, resourceIcon} from "./Regions";
import { useEffect, useState } from "react";

const AvailabilityZone = ({ azname, index,
   azChild,
   searchBar,
   handleResourceIconClick,
   CustomToggle,
   highlightText,
   allOpen, maxCategories , icons, openAccordionForItem, handleAccordionToggle
  }) => {

  const [openParentCards, setOpenParentCards] = useState({});
  const [openChildCards, setOpenChildCards] = useState({});
  const [openGrandchildCards, setOpenGrandchildCards] = useState({});

  const toggleParentCard = (index) => {
    setOpenParentCards((prev) => {
      const newState = { ...prev };
      newState[index] = !prev[index];
      return newState;
    });
  };

  const toggleChildCard = (id) => {
    setOpenChildCards((prev) => {
      const newState2 = { ...prev };
      newState2[id] = !prev[id];
      return newState2;
    });
  };

  const toggleGrandchildCard = (idx) => {
    setOpenGrandchildCards((prev) => {
      const newState3 = { ...prev };
      newState3[idx] = !prev[idx];
      return newState3;
    });
  };

  useEffect(() => {
    handleAccordionToggle(azChild, toggleParentCard, toggleChildCard, toggleGrandchildCard);
  }, [openAccordionForItem]);

  const containerHeight = maxCategories * 54;

  return (
    <div>
      <span className="res-reg-resource">
      <span className="azs-icon-name">
        <img src={azIcon} alt="vector" />
        <span> Active zone {index + 1}</span>
      </span>
        <span className="azs-data">{highlightText(azname, searchBar)}</span>
      </span>
      <Accordion>
      <div style={{ minHeight: `${containerHeight}px` }}>
        {Object.keys(azChild.categories).map((category, index) => {
            const uniqueItems = removeDuplicates(azChild.categories[category]);
            return (
              <div key={category}>
              <Card className="all-category-data">
                <CustomToggle eventKey={category}>
                  <Card.Header className="compute-header" onClick={() => toggleParentCard(category)}>
                    <span className="compute-btn">
                      <span>{icons[category]}</span>
                      <span>{category}</span>
                    </span>
                    <span className="data-count">
                      <span className="category-count">
                      {Object.keys(uniqueItems).length}
                      </span>
                      <span>
                        <img
                          src={downArrow}
                          alt=""
                          className = {allOpen
                            ? (!openParentCards[category] ? 'uparrow' : '')
                            : (openParentCards[category] ? 'uparrow' : '')}
                        />
                      </span>
                    </span>
                  </Card.Header>
                </CustomToggle>
                <Accordion.Collapse
                 eventKey={category}
                 in={allOpen ? !openParentCards[category] : openParentCards[category]}
                >
                  <Card.Body className="card-body">
                    <Accordion>
                      {Object.keys(uniqueItems).map((el, idx) => {
                        return (
                          <Card className="compute-details" key={idx}>
                            <CustomToggle eventKey={idx}>
                              <Card.Header className="compute-details-header" onClick={() => toggleChildCard(el)}>
                                <span className="compute-btn">
                                  {resourceIcon(el)}
                                  <span className={`body-data ${openAccordionForItem?.resource?.type?.includes(":") && openAccordionForItem?.resource?.type.split(":")[0] === el ? "matched-text" : ""}`}>
                                    {highlightText(el, searchBar)}
                                  </span>
                                </span>
                                <span className="data-count">
                                  <span className="category-count">
                                    {Object.keys(uniqueItems[el]).length}
                                  </span>
                                  <span>
                                    <img
                                    src={downArrow}
                                      alt=""
                                      className = {allOpen
                                        ? (!openChildCards[el] ? "arrow-up" : "vector-arrow")
                                        : (openChildCards[el] ? "arrow-up" : "vector-arrow")}
                                    />
                                  </span>
                                </span>
                              </Card.Header>
                            </CustomToggle>
                            <Accordion.Collapse
                             eventKey={idx}
                             in={allOpen ? !openChildCards[el] : openChildCards[el]}
                            >
                              <Card.Body className="compute-details-body">
                                <Accordion>
                                {Object.keys(uniqueItems[el]).map((element, innerIdx) => (
                                  <Card className="compute-details" key={innerIdx}>
                                    <CustomToggle eventKey={innerIdx}>
                                      <Card.Header className="compute-details-header" onClick={() => toggleGrandchildCard(element)}>
                                        <span className="compute-btn">
                                          {resourceIcon(element)}
                                          <span className={`body-data ${openAccordionForItem?.resource?.type?.includes(":") && openAccordionForItem?.resource?.type.split(":")[1] === element ? "matched-text" : ""}`}>
                                          {highlightText(element, searchBar)}
                                          </span>
                                        </span>
                                        <span className="data-count">
                                          <span className="category-count">
                                            {uniqueItems[el][element].length}
                                          </span>
                                          <img
                                          src={downArrow}
                                            alt=""
                                            className = {allOpen
                                              ? (!openGrandchildCards[element] ? "arrow-up" : "vector-arrow")
                                              : (openGrandchildCards[element] ? "arrow-up" : "vector-arrow")}
                                          />
                                        </span>
                                      </Card.Header>
                                    </CustomToggle>
                                    <Accordion.Collapse
                                     eventKey={innerIdx}
                                     in={allOpen ? !openGrandchildCards[element] : openGrandchildCards[element]}
                                    >
                                      <Card.Body className="compute-details-body">
                                        {uniqueItems[el][element].map((item, itemIdx) => (
                                          <div key={itemIdx} className="instances"
                                            onClick={(e) => handleResourceIconClick(e, item)}
                                          >
                                            <span>{resourceIcon(item.type?.includes(":") &&
                                              item.type.split(":")[1])}</span>
                                            <span className={`body-data ${openAccordionForItem?.resource?.name === item.name ? "matched-text" : ""}`}>{highlightText(item.name.length>10
                                              ? item.name.slice(0,10)+"..." : item.name, searchBar)}
                                            </span>
                                          </div>
                                        ))}
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>))}
                                </Accordion>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        );
                      })}
                    </Accordion>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              </div>
            );
          })}
        </div>
      </Accordion>
    </div>
  );
};

export default AvailabilityZone;
