import React, { useEffect, useRef, useContext, useCallback } from "react";
import { Nav, OverlayTrigger } from "react-bootstrap";
import {
  EyeCloseSvg,
  EyeSvg,
  MinusSvg,
  PlusTwoSvg,
  RedoSvg,
  UndoSvg,
} from "../../../../../images/svg/icons";
import styles from "./ZoomInOut.module.css";
import context from "../../../../../store/create-context";
import fitViewSvg from "../../../../../images/fit_view.svg";
import Tooltip from 'react-bootstrap/Tooltip';

import { handleZoomIn, handleZoomOut } from "../../../../../utils/google-analytics";
const ZoomInOut = ({ zoomValue, setZoomValue, zoomTo, fitView, handleUndo, handleRedo, color, setColor }) => {
  //context
  const { showBars, setShowBars, setZoomVal, userId, setEdgeColor } = useContext(context);
  /*Ref*/
  const zoomInputRef = useRef();

  //Handlers Method

  /**
   * Zoom In Out Plus Minus
   */
  const handleChange = useCallback((type) => {
    let interval;
    clearTimeout(interval);
    interval = setTimeout(() => {
      if(type === "plus"){
        handleZoomIn(userId);
      }
      if(type === "minus"){
        handleZoomOut(userId);
      }
      let value = Number(zoomValue) + (type === "plus" ? 0.1 : -0.1)
      value = Math.round(value * 10) / 10;
      if (value >= 0.5 && value <= 2) {
        setZoomVal(zoomValue);
        setZoomValue(value);
        zoomTo(value, { duration: 1500 });  
      }
    }, 500);
  },[setZoomVal, setZoomValue, zoomTo, userId, zoomValue]);

  /**
   * Zoom In Out Input Handler
   */
  const zoomInputHandler = (e) => {
    if (e.key === "Enter") {
      let zoomValue = e.target.value;
      if (zoomValue >= 50 && zoomValue <= 200) {
        zoomValue = (zoomValue / 100).toFixed(2);
        setZoomValue(zoomValue);
        setZoomVal(zoomValue);
        zoomTo(zoomValue, { duration: 1500 });
      }
    }
  };


  useEffect(() => {
    zoomInputRef.current.value = Math.round(zoomValue * 100);
  }, [zoomValue]);

  const handleChangeColor = (color) => {
    setColor(color);
    const hexToRGB = () => {
      const numericValue = parseInt(color?.slice(1), 16);
      const r = (numericValue >> 16) & 0xff;
      const g = (numericValue >> 8) & 0xff;
      const b = numericValue & 0xff;
      return [`${r}`, `${g}`, `${b}`];
    };
    const rgbValue = hexToRGB(color);
    const brightness = Math.round(
      (parseInt(rgbValue[0]) * 299 +
        parseInt(rgbValue[1]) * 587 +
        parseInt(rgbValue[2]) * 114) /
        1000
    );
    const edgeColor = brightness > 125 ? "black" : "white";
    setEdgeColor(edgeColor);
  }
  const keyDownZoomHandler = useCallback((e) => {
    if (e.key === "+") {
      handleChange('plus');
    }
    if(e.key === "-"){
      handleChange('minus');
    }
    if(e.key === "r"){
      fitView()
    }
  },[handleChange, fitView]);

  useEffect(() => {
    document.addEventListener("keydown", keyDownZoomHandler);
    return () => {
    document.removeEventListener("keydown", keyDownZoomHandler);
    };
  },[keyDownZoomHandler]);

  return (
    <>
      <div className={styles["main-container"]}>
        <Nav variant="pills" className={`${styles["undo-redo-container"]}`}>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          trigger={["hover", "focus"]}
          overlay={<Tooltip id = "tooltip-top"><div className={styles["toolbar"]}>Fitview of graph</div></Tooltip>}
        >
          <Nav.Item className="">
            <Nav.Link onClick = {() => handleRedo()} className={styles["custom-btn"]}>
              <img onClick={() => fitView()} className = {`${styles["fitViewBtn"]}`} src = {fitViewSvg} alt="fit_view"/>
            </Nav.Link>
          </Nav.Item>
        </OverlayTrigger>
       </Nav>
        <Nav
          variant="pills"
          className={`${styles["pills-container"]} ${styles["eye-container"]}`}
        >
          <OverlayTrigger
             placement="left"
             delay = {{show: 250, hide: 400}}
             overlay={<Tooltip id = "tooltip-top"><div className={styles["toolbar"]}>Set background Color</div></Tooltip>}
             trigger={["hover", "focus"]}
          >
          <Nav.Item className="">
              <div style={{paddingTop: "10px"}}> <input type = "color" value = {color} onChange = {(e) => handleChangeColor(e.target.value)}></input></div>
          </Nav.Item>
          </OverlayTrigger>
          <OverlayTrigger
            placement="left"
            delay = {{show: 250, hide: 400}}
            overlay={<Tooltip id = "tooltip-top"><div className={styles["toolbar"]}>Hide navbar and sidebar</div></Tooltip>}
            trigger={["hover", "focus"]}
          >
          <Nav.Item className="">
            <Nav.Link
              href=""
              className={`${styles["custom-btn"]} ${styles["range-btn"]} ${
                showBars.showNavbar === false && styles["active"]
              }`}
              onClick={() =>
                setShowBars((prev) => {
                  return {
                    ...prev,
                    showNavbar: !prev.showNavbar,
                    showLeftSidebar: !prev.showNavbar,
                    showFooter: true,
                  };
                })
              }
            >
              {showBars.showNavbar === true ? <EyeSvg /> : <EyeCloseSvg />}
            </Nav.Link>
          </Nav.Item>
        </OverlayTrigger>
        </Nav>
        <Nav
          variant="pills"
          className={`${styles["pills-container"]} ${styles["plus-minus-container"]}`}
        >
       <OverlayTrigger
           placement="left"
           delay = {{show: 250, hide: 400}}
           overlay={<Tooltip id = "tooltip-top"><div className={styles["toolbar"]}>ZoomIn Graph</div></Tooltip>}
           trigger={["hover", "focus"]}
       >
        <Nav.Item className="">
              <Nav.Link
                href=""
                className={`${styles["custom-btn"]} ${styles["range-btn"]}`}
                onClick={() => {
                  handleChange("plus");
                }}
              >
                <PlusTwoSvg />
              </Nav.Link>
        </Nav.Item>
       </OverlayTrigger>
       <OverlayTrigger
           placement="left"
           delay = {{show: 250, hide: 400}}
           overlay={<Tooltip id = "tooltip-top"><div className={styles["toolbar"]}>Change ZoomIn/ZoomOut</div></Tooltip>}
           trigger={["hover", "focus"]}
       >
          <Nav.Item className="">
            <Nav.Link
              href=""
              className={`${styles["custom-btn"]} ${styles["range-percentage-container"]}`}
            >
              <input
                type="number"
                ref={zoomInputRef}
                onKeyUp={zoomInputHandler}
                className={styles["zoom_input"]}
                minLength="1"
                maxLength="3"
                min="50"
                max="200"
              />
              <span className="text-white">%</span>
            </Nav.Link>
          </Nav.Item>
       </OverlayTrigger>
       <OverlayTrigger
           placement="left"
           delay = {{show: 250, hide: 400}}
           overlay={<Tooltip id = "tooltip-top"><div className={styles["toolbar"]}>ZoomOut Graph</div></Tooltip>}
           trigger={["hover", "focus"]}
       >
          <Nav.Item className="">
            <Nav.Link
              href=""
              className={`${styles["custom-btn"]} ${styles["range-btn"]}`}
              onClick={() => {
                handleChange("minus");
              }}
            >
              <MinusSvg />
            </Nav.Link>
          </Nav.Item>
       </OverlayTrigger>
        </Nav>

        <Nav variant="pills" className={`${styles["undo-redo-container"]}`}>
        <OverlayTrigger
           placement="left"
           delay = {{show: 250, hide: 400}}
           overlay={<Tooltip id = "tooltip-top"><div className={styles["toolbar"]}>Undo</div></Tooltip>}
           trigger={["hover", "focus"]}
       >
          <Nav.Item>
            <Nav.Link  onClick = {() => handleUndo()} className={`${styles["custom-btn"]} ${styles["range-btn"]}`}>
              <UndoSvg />
            </Nav.Link>
          </Nav.Item>
        </OverlayTrigger>
        </Nav>
        <Nav variant="pills" className={`${styles["undo-redo-container"]}`}>
        <OverlayTrigger
           placement="left"
           delay = {{show: 250, hide: 400}}
           overlay={<Tooltip id = "tooltip-top"><div className={styles["toolbar"]}>Redo</div></Tooltip>}
           trigger={["hover", "focus"]}
       >
          <Nav.Item>
            <Nav.Link onClick = {() => handleRedo()} className={`${styles["custom-btn"]} ${styles["redo-range-btn"]}`}>
              <RedoSvg />
            </Nav.Link>
          </Nav.Item>
       </OverlayTrigger>
       </Nav>
      </div>
    </>
  );
};

export default ZoomInOut;
