import Header from "../../components/header";
import "./NewArch.css";
import vector from "../../../../../images/Vector .svg";
import codeView from "../../../../../images/codeView_frame.svg";
import checkImg from "../../../../../images/check-broken.svg";
import highlighted from "../../../../../images/highlights.svg";
import refresh from "../../../../../images/arrow-refresh.svg";
import sync from "../../../../../images/synicon.svg";
import security from "../../../../../images/security.svg";
import integraionAI from "../../../../../images/integration_Ai.svg";
import comprehesive from "../../../../../images/comprehensive.svg";
import ProductCarousel from "./ProductCarousel";
import Footer from "../../../../NewLanding2/Footer";

const Index = () => {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="product-title">AI-powered</div>
        <strong className="title-strong">Cloud Visualizer</strong>
        <div className="image-container">
          <img className="image" src={vector} alt="Vector" />
        </div>
        <div className="description-container">
          <p className="description">
            JigsawML's Cloud Visualizer offers a unified, high-level visualization of your entire cloud software,
            providing detailed insights into both software architecture and infrastructure.
          </p>
        </div>
        <div className="codeView-img">
          <img src={codeView} alt="codeView_frame" className="code-view-img"/>
        </div>
      </div>
      <ProductCarousel />
      {/* Use case section */}
      <div className="usecase-section">
        <div className="inner-container">
          <div className="use-cases">
            <div className="usecase-title">
              <span><img src={checkImg} alt="check-broken" /></span>
              <span><strong>Use Cases</strong></span>
            </div>
            <span>
              <img src={highlighted} alt="highlighted" className="highlighted-img"/>
            </span>
          </div>
          <span className="refresh-icon"><img src={refresh} alt="arrow-refresh" /></span>
        </div>
      </div>
    {/* Benefits section */}
      <div className="key-benefits-container">
      <div className="key-benefits-heading">
        <span>
          <strong>Key Benefits</strong>
        </span>
      </div>
      <div className="key-benefits-row">
        <div className="key-benefit-card card-sync">
          <span>
            <img src={sync} alt="synicon" />
          </span>
          <span>
            <strong>Sync on Your Terms</strong>
          </span>
          <span>
            With flexible sync options, you can set your architectures to auto-sync
            or update them on demand, putting you in charge
          </span>
        </div>
        <div className="key-benefit-card card-security">
          <span>
            <img src={security} alt="security" />
          </span>
          <span>
            <strong>Absolute Privacy & Security</strong>
          </span>
          <span>
            Data privacy and security are ensured with read-only access, full
            disconnection and data removal, and secure access control protocols
          </span>
        </div>
      </div>
      <div className="key-benefits-row">
        <div className="key-benefit-card card-ai">
          <span>
            <img src={integraionAI} alt="integration_AI" />
          </span>
          <span>
            <strong>Integrated AI Companion</strong>
          </span>
          <span>
            Swift, accurate, and contextual answers to queries, no expertise needed
            with, 'Ask AI'
          </span>
        </div>
        <div className="key-benefit-card card-comprehensive">
          <span>
            <img src={comprehesive} alt="comprehensive" />
          </span>
          <span>
            <strong>Comprehensive Visibility</strong>
          </span>
          <span>
            Unlock an in-depth view of how different components interact and the
            purpose behind each asset in your system
          </span>
        </div>
      </div>
    </div>
    <Footer />
    </div>
  )
}

export default Index;
