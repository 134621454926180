import React, { useContext, useState } from "react";
import ModalUI from "../../components/UI/ModalUI/ModalUI";
import { Row, Col, Form } from "react-bootstrap";
import { TickSvg } from "../../../images/svg/icons";

const DeleteProjectModal = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const { 
    show = false, 
    handleClose = () => {}, 
    modalTitle = "",
    project =null,
    deleteProjectFn = () => {}
} = props;


  return (
    <ModalUI
      show={show}
      handleClose={handleClose}
      modalTitle={modalTitle}
      theme="dark"
      classes="border-no"
    >
      <Row>
        <Col>
          <h5>{project?.name}</h5>
          <p>{project?.id}</p>
          <button
            className="blue-btn delete-modal-btn"
            style={{ float: "right" }}
            onClick={deleteProjectFn}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <span>
              <TickSvg color = {isHovered? '#5A46FF' : 'white'}/>
            </span>
            <span style = {{color: isHovered ? "#5A46FF" : "white"}}>Delete</span>
          </button>
        </Col>
      </Row>
    </ModalUI>
  );
};

export default DeleteProjectModal;
