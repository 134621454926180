import React, { useState } from "react";
import styles from "./landing2.module.css";
import cursiveDownArrow from "../../images/cursiveDownArrow.svg";
import awsBox from "../../images/awsBox.svg";
import azureBox from "../../images/azureBox.svg";
import visionGif from "../../images/our_vision.gif";
import useFormHandler from './useFormHandler';
import Winnertrophy from '../../images/trophy-front-gradient.svg';
import crossImg from "../../images/crossBtn.svg";
import utilsImage from "../../images/utilsImage.svg"

import {
  BlueTwoSvg,
  BlueSvg,
  BlueThreeSvg,
  SetOneSvg,
  BrownTwoSvg,
  BrownThreeSvg,
  PurpleTwoSvg,
  PurpleThreeSvg,
  GreenTwoSvg,
  GreenThreeSvg,
} from "../../images/svg";
import Footer from "./Footer";
import Navbar from "./Navbar";
const Testimonials = React.lazy(() => import("./Testimonials"));
const AccessModal = React.lazy(() => import("./AccessModal"));
const ThanksModal = React.lazy(() => import("./ThanksModal"));

const UpdatedLanding = () => {

  const {
    email,
    name,
    affiliation,
    message,
    isValidEmail,
    emailError,
    showAccessModal,
    showThanksModal,
    isSubmitting,
    formRef,
    setEmailHandler,
    setNameHandler,
    setAffiliationHandler,
    setMessageHandler,
    formSubmitHandler,
    setEmail,
    setName,
    setAffiliation,
    setMessage,
    setIsValidEmail,
    setEmailError,
    setShowAccessModal,
    setShowThanksModal,
  } = useFormHandler();

  const [showbox, setShowBox] = useState(true);

  return (
    <>
      <div
        className={`styles["bg-light-grayish-blue"] ${styles["navbar-container"]}`} style={{background:'#E9EDF5'}}
      >
        {showbox &&
        <div className={styles["notificationContainer"]} >
          <div>
              <img src={Winnertrophy} alt="trophy-front-gradient" className={styles["trophyImage"]} />
              Yay! We won the <a href="https://www.developerweek.com/cloudx/awards/#winners" target="_blank" rel="noopener noreferrer">
              CloudX Award 2024
            </a>
          </div>
          <span className={styles["closeIcon"]} onClick={() => setShowBox(false)}>
            <img src={crossImg} alt="crossBtn" />
          </span>
        </div>}
        <Navbar setEmail={setEmail}
         setName={setName} setAffiliation={setAffiliation} setMessage={setMessage} setEmailError={setEmailError}
         setShowAccessModal={setShowAccessModal} setIsValidEmail={setIsValidEmail} />
        {/* Cloud Section Start */}
        <div className={`container-fluid h-auto ${styles["cloud-outer"]}`}>
          <div className={`${styles["cloud_flex_direction"]} row align-items-center`}>
            <div className="col-12 col-lg-6 pt-4">
              <h1 className="display-3">
                An AI-powered Cloud Intelligence Platform.
              </h1>
              <div className={styles["hidden-mobile"]}>
                <p className="fs-5 my-3">
                  JigsawML sits on top of your cloud, considers the world you are in, 
                  and allows you to manage your cloud systems more effectively.
                </p>
                <button 
                  className={`${styles["btn"]}`}
                  type="button"
                  onClick={() => {
                    setEmail("");
                    setName("");
                    setAffiliation("");
                    setMessage("");
                    setIsValidEmail(null);
                    setEmailError(false);
                    setShowAccessModal(true);
                  }}
                >
                  Sign up for Beta
                </button>
                <div>
                  <span style={{ fontSize: '14px', fontFamily: 'Work Sans, sans-serif', fontWeight: 600}}>
                    Supported by
                  </span>
                  <div style={{ display: 'flex', gap: '8px', paddingTop:'10px', paddingBottom:'12px'}}>
                    <img src={awsBox} alt="AWS Icon" style={{ marginRight: '5px', marginBottom: '16px', width: "80px", height:"88px" }} />
                    <img src={azureBox} alt="Azure Icon" style={{ marginBottom: '16px', width: "80px", height:"88px"}} />
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles[`updown_animation`]} col-12 col-lg-6 d-flex flex-wrap ${styles["col-six-right"]}`}>
              {/* ///////////////ONE START////////////////// */}
              <div className={`position-relative w-50`}>
                <div  className={styles["first-tile"]}>
                <div className={styles["set-wrapper"]}>
                  {" "}
                  <SetOneSvg />
                </div>
                <div className="position-relative ">
                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["blue-btn-svg-one"]}`}
                    id={styles["svg-zero"]}
                    style={{}}
                  >
                    <BlueSvg />
                  </div>

                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["blue-btn-svg-two"]}`}
                    id={styles["svg-one"]}
                    style={{}}
                  >
                    <BlueTwoSvg />
                  </div>

                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["blue-btn-svg-three"]}`}
                    id={styles["svg-one"]}
                    style={{}}
                  >
                    <BlueThreeSvg />
                  </div>
                </div>
                </div>
              </div>
              {/* ///////////////ONE END////////////////// */}

              {/* ///////////////TWO START////////////////// */}
              <div
                className={`position-relative w-50 z-1`}
              >
              <div className={styles["second-tile"]}>
                <div className={styles["set-wrapper"]}>
                  <SetOneSvg />
                </div>
                <div className="position-relative ">
                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["purple-btn-svg-one"]}`}
                    id={styles["svg-zero"]}
                    style={{}}
                  >
                    <BlueSvg />
                  </div>

                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["purple-btn-svg-two"]}`}
                    id={styles["svg-one"]}
                    style={{}}
                  >
                    <PurpleTwoSvg />
                  </div>

                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["purple-btn-svg-three"]}`}
                    id={styles["svg-one"]}
                    style={{}}
                  >
                    <PurpleThreeSvg />
                  </div>
                </div>
                </div>
              </div>
              {/* ///////////////TWO END////////////////// */}

              {/* ///////////////THREE START////////////////// */}
              <div
                className={`position-relative w-50 z-1`}
              >
              <div  className={styles["third-tile"]}>
                <div className={styles["set-wrapper"]}>
                  <SetOneSvg />
                </div>
                <div className="position-relative ">
                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["green-btn-svg-one"]}`}
                    id={styles["svg-zero"]}
                    style={{}}
                  >
                    <BlueSvg />
                  </div>

                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["green-btn-svg-two"]}`}
                    id={styles["svg-one"]}
                    style={{}}
                  >
                    <GreenTwoSvg />
                  </div>

                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["green-btn-svg-three"]}`}
                    id={styles["svg-one"]}
                    style={{}}
                  >
                    <GreenThreeSvg />
                  </div>
                </div>
                </div>
              </div>
              {/* ///////////////THREE END////////////////// */}

              {/* ///////////////FOUR START////////////////// */}
              <div
                className={`position-relative w-50 z-1`}
              >
              <div  className={styles["fourth-tile"]}>
                <div className={styles["set-wrapper"]}>
                  <SetOneSvg />
                </div>
                <div className="position-relative ">
                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["brown-btn-svg-one"]}`}
                    id={styles["svg-zero"]}
                    style={{}}
                  >
                    <BlueSvg />
                  </div>

                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["brown-btn-svg-two"]}`}
                    id={styles["svg-one"]}
                    style={{}}
                  >
                    <BrownTwoSvg />
                  </div>

                  <div
                    className={`position-absolute ${styles["btn-svg"]} ${styles["brown-btn-svg-three"]}`}
                    id={styles["svg-one"]}
                    style={{}}
                  >
                    <BrownThreeSvg />
                  </div>
                </div>
                </div>
              </div>
              {/* ///////////////FOUR END////////////////// */}

            </div>
            <div className={`${styles["mobileView"]} ${styles["navbar-container"]}`} style={{background:'#E9EDF5'}}>
              <div>
                <p className={styles.textParagraph}>
                  JigsawML sits on top of your cloud, considers the world you are in, 
                  and allows you to manage your cloud systems more effectively.</p>
              </div>
              <button
                className={`${styles["btn"]}`}
                type="button"
                onClick={() => {
                  setEmail("");
                  setName("");
                  setAffiliation("");
                  setMessage("");
                  setIsValidEmail(null);
                  setEmailError(false);
                  setShowAccessModal(true);
                }}
              >
                Sign up for Beta
              </button>
              <div>
                <span style={{ fontSize: '14px', fontFamily: 'Work Sans, sans-serif', fontWeight: 600}}>
                  Supported by
                </span>
                <div style={{ display: 'flex', gap: '8px', paddingTop:'10px', paddingBottom:'12px', justifyContent:'center'}}>
                  <img src={awsBox} alt="AWS Icon" style={{ marginRight: '5px', marginBottom: '16px', width: "80px", height:"88px" }} />
                  <img src={azureBox} alt="Azure Icon" style={{ marginBottom: '16px', width: "80px", height:"88px"}} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Cloud Section End */}

        {/* Our Vision Section Start */}
        <div className="container-fluid h-auto" >
          <div className={`${styles["vision-section"]} row align-items-center`}>
            <div className="col-md-6 col-sm-12">
                <p className={`${styles["vision-title"]}`}><strong>Our Vision</strong></p>
                <p className={`${styles["vision-detail"]} text-white text-start`}>
                  A Cloud that is much simpler to operate.</p>
                <p className={`${styles["vision-more-detail"]} text-white text-start`}>
                  By combining cutting-edge AI with thoughtful, human-centered design, we are taking a new top-down 
                  approach that transforms how cloud is managed for the entire industry.
                </p>
            </div>
            <div className="col-md-6">
              <img src={visionGif} alt="Vision GIF" className="img-fluid" loading="lazy" style={{ width: '80%', height: "100%" }}/>
            </div>
          </div>
        </div>
        {/* Our Vision Section End */}

        {/* You think Section Start */}
        <div style={{background:'#FFFFFF', borderBottom:'1px solid rgba(0, 0, 0, 0.1)', backgroundColor:'#fff'}}>
        <div className={`position-relative h-auto ${styles["puzzleDivContainer"]}`}>
          <div className={styles["puzzleTextContainer"]}>
            <div className={styles["puzzleTextFlex"]}>
              <p className={styles["puzzle-title"]}>
                <div>Your Cloud Puzzle<strong> - solved.</strong></div>
              </p>
            </div>
            <div>
              <p className={styles.textParagraph}>
                We piece together your complex systems into a clear, adaptable whole—ready to scale, optimize, and thrive in an ever-evolving tech landscape.</p>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <img src={utilsImage} alt="utilsImage" className={styles.utilsImage} />
          </div>
        </div>
        </div>
        {/* You think Section End */}

        {/* Testimonials Slider Section Start */}
        <div className={`container-fluid h-auto p-0 bg-white ${styles["testimonials-section"]}`}>
          {/* consistent design start */}
          <br/><br/>
            <p className={`${styles["community-heading"]}`}>
              <span>Here's what the community is saying</span>
            </p>
            <div className={styles["arrow-container"]}>
            <img
              src={cursiveDownArrow}
              alt=""
              className={styles["arrow-img"]}
            />
            </div>
          {/* consistent design end */}

          <div className={`py-1`}>
            <Testimonials />
          </div>
          <br/><br/>
        </div>
        {/* Testimonials Slider Section End */}

        {/* Footer Section Start */}
        <Footer />
        {/* Footer Section End */}
      </div>
      {/* style={{visibility: "hidden", position: "absolute"}} */}
      <svg
        width="10"
        height="10"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <defs>
          <filter id="round">
            <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="goo"
            />
            <feComposite in="SourceGraphic" in2="goo" operator="atop" />
          </filter>
        </defs>
      </svg>
      <AccessModal
        show={showAccessModal}
        handleClose={() => setShowAccessModal(false)}
        formRef={formRef}
        name={name}
        affiliation={affiliation}
        message={message}
        email={email}
        isValidEmail={isValidEmail}
        emailError={emailError}
        isSubmitting={isSubmitting}
        setNameHandler={setNameHandler}
        setAffiliationHandler={setAffiliationHandler}
        setMessageHandler={setMessageHandler}
        setEmailHandler={setEmailHandler}
        formSubmitHandler={formSubmitHandler}
      />
      <ThanksModal
        show={showThanksModal}
        handleClose={() => setShowThanksModal(false)}
    />
    </>
  );
};

export default UpdatedLanding;
