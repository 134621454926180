import React, { useState, useEffect, useContext } from "react";
import { Container, Navbar, Nav, Dropdown, Tooltip } from "react-bootstrap";
import styles from "./view-edit-architecture.module.css";
import PlusButtonDropdown from "./PlusButtonDropdown";
import DownloadButtonDropdown from "./ExportButtonDropdown";
import {ChatSvg } from "../../../images/svg/icons";
import WebSocketInstance from "../../../common/WebSocketInstance";
import { Spinner } from "react-bootstrap";
import OverLayTooltip from "./components/OverLayTooltip/OverLayTooltip";
import { useNavigate } from "react-router-dom";
import LinkModal from "./components/LinkModal/LinkModal";
import DataTour from "./components/DataTour/DataTour";
import Toastify from "../../../common/components/Toastify/Toastify";
import NavbarView from "./NavbarView";
import homeIcon from "../../../images/homeIcon.svg";
import "./navbar.css";
import { OverlayTrigger } from "react-bootstrap";
import CreateContext from "../../../store/create-context";
import { ProjectDescription } from "../../NewDashboard/components/ProjectDescription";
import logoutSvg from "../../../images/logout.svg";
import avatar from "../../../images/avatar_icon.png";
import LeftSideActiveLog from "./LeftSideActiveLog";
import projectIcon from "../../../images/project-icon.svg"
import BetaButtonForScanner from "./BetaButtonForScanner";

const NavbarUI = ({
  sResponse,
  projectName,
  projectDescription,
  setSResponse,
  downloadPDF,
  pendingActivity,
  setPendingActivity,
  checkActivity,
  setCheckActivity,
  logoutHandler,
  sandboxMode,
  sidebarData,
  checkCloudActivity,
  setCheckCloudActivity
}) => {
  /*context*/
  const { setActiveSection, showToastr, setShowToastr, activeSection} = useContext(CreateContext);
  //states
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [projectNameToDisplay, setProjectNameToDisplay] = useState(projectName);
  const [socket, setSocket] = useState(null);
  const [showDes, setShowDes] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [activeNavTab, setActiveNavTab] = useState("");
  const [activityLogData, setActivityLogData] = useState(null);
  const isDisabled = window.location.host.split(".")[0] === "scanner";

  const linkModalHandler = () => {
    setIsLinkModalOpen(!isLinkModalOpen);
  };

  const onGitConnect = (event) => {
    if (event?.success === false || !event) {
      setShowToastr({
        show: true,
        type: "error",
        text: event?.error,
      });
    } else {
      if (
        event.jml_workspace_id === sessionStorage.getItem("jml_workspace_id")
      ) {
        sessionStorage.setItem("jml_action_id", event.jml_action_id);
      }
    }
  };

  let intervalId;
  let firstname = localStorage.getItem("JMLFirstname");
  if (!firstname) {
    firstname = "User";
  }

  const projectStateReceipt = (event) => {
    if (event?.success === false) {
      setShowToastr({
        show: true,
        type: "error",
        text: event?.error,
      });
    } else {
      if (
        event.jml_workspace_id === sessionStorage.getItem("jml_workspace_id") &&
        event.jml_action_id === sessionStorage.getItem("jml_action_id")
      ) {
        setSResponse(event.architecture);
        setShowToastr({
          show: true,
          type: "success",
          text: "Your repository has been added to the architecture",
        });
      } else {
      }
    }
  };

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  useEffect(() => {
    const socket = WebSocketInstance.getInstance();
    setSocket(socket);
    const fetchProjectStatus = () => {
      const projectId = sessionStorage.getItem("jml_workspace_id");
      setTimeout(() => {
        socket.send("get_project_status", { project_id: projectId });
      }, 3000);
    };

    const onProjectStatus = (event) => {
      if (event.has_pending_actions === false) {
        setPendingActivity(false);
        setShowToastr({
          show: false,
          type: "info",
          text: "",
        });
        const projectId = sessionStorage.getItem("jml_workspace_id");
        socket.send(
          "retrieve_project_state",
          JSON.stringify({ jml_workspace_id: projectId })
        );
        setCheckActivity(false); // Set checkActivity to false
        clearInterval(intervalId); // Clear the interval
      } else {
        setPendingActivity(true);
      }
    };

    if (checkActivity) {
      socket.addEventListener("retrieve_project_state", projectStateReceipt);
      socket.addEventListener("get_project_status", onProjectStatus);
      sleep(1000).then(() => {
        setPendingActivity(true);
        fetchProjectStatus();
        intervalId = setInterval(fetchProjectStatus, 5000); // every 5 seconds
      });
    } else {
      socket.removeEventListener("retrieve_project_state", projectStateReceipt);
      socket.removeEventListener("get_project_status", onProjectStatus);
      clearInterval(intervalId); // Clear the interval
    }

     return () => {
      socket.removeEventListener("retrieve_project_state", projectStateReceipt);
      socket.removeEventListener("get_project_status", onProjectStatus);
      clearInterval(intervalId); // Clear the interval
    };
  }, [checkActivity]);

  useEffect (() => {
    let activitylog = { actions: [], uploads: [], connections: [] };
    let logs = sidebarData["activity_log"];
    if (Array.isArray(logs) && logs.length > 0) {
      logs.forEach((log) => {
        if (log.activity_type.toLowerCase() === "actions") {
          activitylog["actions"].push(log);
        }
        if (log.activity_type.toLowerCase() === "uploads") {
          activitylog["uploads"].push(log);
        }
        if (log.activity_type.toLowerCase() === "connections") {
          activitylog["connections"].push(log);
        }
      });
    }
    setActivityLogData(activitylog);
  },[sidebarData]);

  useEffect(() => {
    if (socket !== null) {
      socket.addEventListener("edit_project_name", (response) => {
        if (response.success) {
          setProjectNameToDisplay(response.new_project_name);
          setShowToastr({
            show: true,
            type: "success",
            text: `Project name updated to: ${response.new_project_name}`,
          });
        } else {
          setShowToastr({
            show: true,
            type: "error",
            text: `${response.error}`,
          });
        }
      });
    }
    return () => {
      if(socket !== null){
        socket.removeEventListener("edit_project_name", (response) => {
          if (response.success) {
            setProjectNameToDisplay(response.new_project_name);
            setShowToastr({
              show: true,
              type: "success",
              text: `Project name updated to: ${response.new_project_name}`,
            });
          } else {
            setShowToastr({
              show: true,
              type: "error",
              text: `${response.error}`,
            });
          }
        });
      }}
  }, [socket]);

  useEffect(() =>{
    setProjectNameToDisplay(projectName)
  },[projectName])

  useEffect(() => {
    const socket = WebSocketInstance.getInstance();
    socket.addEventListener("git_connect", onGitConnect);
    setCheckActivity(true); // Set checkActivity to true when the component initially loads

    return () => {
      socket.removeEventListener("git_connect", onGitConnect);
    };
  }, []);

  const navigate = useNavigate();

  const handleOnBlur = (e) => {
    if (e.target.value.trim().length > 0) {
      if (projectNameToDisplay !== e.target.value.trim()) {
        handleEditProjectName(
          sessionStorage.getItem("jml_workspace_id"),
          e.target.value
        );
        setProjectNameToDisplay(e.target.value);
      }
    } else {
      setShowToastr({
        show: true,
        type: "error",
        text: `Failed to update project name`,
      });
    }
    setIsTyping(false);
  };

  const handleEditProjectName = (projectId, newProjectName) => {
    const socket = WebSocketInstance.getInstance(); // Ensure you have access to your WebSocket instance

    // Emit the event with the necessary data
    socket.send("edit_project_name", {
      project_id: projectId,
      new_project_name: newProjectName,
    });
  };

  const homeNavigateHandler = (e) => {
    e.preventDefault();
    if(!isDisabled){
      setActiveSection("");
      let ele = document.querySelector(".adjacent-component");
      ele && (ele.innerHTML = "");
      sessionStorage.removeItem("jml_workspace_id");
      setSResponse("");
      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
    }
  };

  const toggle = () => {
   setIsOpened((prev) => !prev)
  }

  const handleProjectName = () =>{
    if(!isDisabled){
     setIsTyping(true)
    }
  }

  return (
    <>
      <Navbar expand="lg" className={activeSection === "infra_view" || isDisabled ? styles["cust-navbar-infra"] : styles["cust-navbar"]}>
        <Container
          fluid
          style={{
            height: "66px",
            maxHeight: "auto",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          <div className={styles["header-btn-div"]}>
            {!isDisabled &&
            <div className={`${styles["main-btn"]} ${activeSection === "infra_view" ? styles["main-btn-infra"] : styles["bg-black"]}`}>
              <Dropdown className={`mx-auto ${styles["patch-dropdown"]}`}>
                <OverLayTooltip placement="bottom" info="Home">
                  <a
                    href="#"
                    className="nav-link"
                    onClick={homeNavigateHandler}
                    data-testid="home-btn"
                  >
                    <span>
                      <img src = {homeIcon}  alt="homeIcon"/>
                    </span>
                    <span>Home</span>
                  </a>
                </OverLayTooltip>
                <Dropdown.Menu className={styles["patch-dropdown-menu"]}>
                  <Dropdown.Item href="#/action-1">File</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Snapshot</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Export</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>}
            {/* Home */}
            {/* All View List */}
            <DataTour tourDataId="reactour__all_view">
              <NavbarView sResponse={sResponse} />
            </DataTour>
          </div>
          <Navbar.Collapse className={styles["basic-navbar-nav"]}>
            {/* Left-aligned Items*/}
            <Nav className={`ml-auto ${styles["custom-navbar-nav"]}`}></Nav>
            <Nav
              className={`${styles["navigation-wrapper-bg"]}`}
              onMouseLeave={() => setShowDes(false)}
            >
              <div className={`${styles["project_name"]} ${isTyping && styles['edit_project_name']}
               ${activeSection === "infra_view" && styles['project_name_infra']}`} onMouseEnter={() => setShowDes(true)}>
                <img src = {projectIcon} alt="project" style={{opacity:'0.9'}}/>
                {isTyping ? (
                  <input
                    style={{
                      backgroundColor: "#161616",
                      color: "white",
                      border: "none",
                    }}
                    defaultValue={projectNameToDisplay}
                    onBlur={handleOnBlur}
                    autoFocus
                  />
                ) : (
                  <span onClick={handleProjectName}>
                    {projectNameToDisplay}
                  </span>
                )}
              </div>
              {showDes && !isTyping &&
              <div><ProjectDescription descStyle={true} description={projectDescription} name={projectName}/></div>}
            </Nav>
            {/* Center-aligned Items End*/}

            {/*Right-aligned Items*/}
            <Nav className={styles["navigation-wrapper"]}>
              {(pendingActivity || checkCloudActivity) &&
               <OverlayTrigger
               placement="bottom"
               delay = {{show: 250, hide: 400}}
               overlay={<Tooltip id = "tooltip-top"><div className={styles["spinner"]}>Your request is still being processed</div></Tooltip>}
               trigger={["hover", "focus"]}
              >
                <Spinner animation="border"/>
              </OverlayTrigger>
            }

              <div className={styles["button-wrapper"]}>
                <DataTour tourDataId="reactour__upload_btn">
                  {!sandboxMode && (
                    <PlusButtonDropdown
                      setCheckActivity={setCheckActivity}
                      setPendingActivity={setPendingActivity}
                      setShowToastr={setShowToastr}
                      sandboxMode={sandboxMode}
                      checkCloudActivity={checkCloudActivity}
                      setCheckCloudActivity={setCheckCloudActivity}
                    />
                  )}
                </DataTour>

                <DataTour tourDataId="reactour__download_btn">
                  {/* {( */}
                  <DownloadButtonDropdown
                    downloadPDF={downloadPDF}
                    setCheckActivity={setCheckActivity}
                    projectName={projectName}
                    disabled={Object.keys(sResponse).length === 0}
                  />
                  {/* )} */}
                </DataTour>
                {/* Chat */}
                <OverLayTooltip placement="bottom" info="Chat">
                  <Nav.Link
                    hidden
                    to="/"
                    className={`${styles["navigation-item"]}`}
                  >
                    <ChatSvg />
                  </Nav.Link>
                </OverLayTooltip>
                {/* Chat */}
              </div>

              {!isDisabled ? (
              <Dropdown
                className={`mx-auto ${styles["patch-dropdown"]}`}
                drop="start"
                variant="success"
                onToggle={toggle}
                show={isOpened && !isDisabled}
              >
                <Dropdown.Toggle>
                  <img
                    src={avatar}
                    className={`${styles["user-img"]} ${styles["user-img-highlight"]}`}
                    loading="lazy"
                    alt="avatar"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles["user-dropdown-toggle"]}>
                  <Dropdown.Item href="#">
                    <div style={{display:"flex", gap:"12px", padding:"5px 0px"}}>
                      <img src={avatar} className={styles["profile-img"]} alt="avatar_icon" />
                      <span>{firstname}</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={logoutHandler}>
                    <div style={{display:"flex", gap:"12px", padding:"5px 0px"}}>
                      <img src={logoutSvg} style={{ marginRight: '4px' }} alt="logout" />
                      <span>Logout</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <BetaButtonForScanner />
            )}
            </Nav>
            {/*Right-aligned Items End*/}
          </Navbar.Collapse>
          <Navbar.Toggle aria-controls=""></Navbar.Toggle>
        </Container>
      </Navbar>
      <LinkModal
        show={isLinkModalOpen}
        handleClose={linkModalHandler}
        setCheckActivity={setCheckActivity}
        setPendingActivity={setPendingActivity}
      />
      <Toastify
        show={showToastr["show"]}
        type={showToastr["type"]}
        message={showToastr["text"]}
        handleClose={() => {
          setCheckCloudActivity(false);
          setShowToastr((props) => {
            return { ...props, show: false };
          });
        }}
      />
      {activeNavTab === "activitylog" && (
        <LeftSideActiveLog activitylog={activityLogData} />
      )}
    </>
  );
};

export default NavbarUI;
