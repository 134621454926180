import io from "socket.io-client";

class WebSocketInstance {
  static instance = null;

  constructor() {
    this.socketRef = null;
    this.reconnectTimeout = null;
    this.reconnectDelay = 15000; // Delay in milliseconds
    this.customListeners = {}; // Store custom listeners
    this.reconnectAttempts = 5; // Number of reconnect attempts
  }

  static getInstance() {
    if (!WebSocketInstance.instance) {
      WebSocketInstance.instance = new WebSocketInstance();
    }

    if (!WebSocketInstance.instance.socketRef)
      WebSocketInstance.instance.connect();

    return WebSocketInstance.instance;
  }

  connect() {
    // const stack = new Error().stack;
    // console.log(stack);

    const path = `${process.env.REACT_APP_SIMPLIFAI_BACKEND_WSS_URL}`;
    const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "dev";
    isDevEnvironment && console.log("JMLAuthToken: ", localStorage.getItem("JMLAuthToken"));
    this.socketRef = io(path, {
      query: { token: localStorage.getItem("JMLAuthToken") },
    });

    // Listeners for connect and disconnect events
    this.socketRef.on("connect", () => {
      isDevEnvironment && console.log("Successfully connected to socket");
      this.attachCustomListeners(); // Attach the custom listeners
      this.reconnectAttempts = 5;
    });

    this.socketRef.on("disconnect", () => {
      isDevEnvironment && console.log("Disconnected from socket");
      this.socketRef = null;
      // Reconnect after a delay
      this.attemptReconnect();
      clearTimeout(this.reconnectTimeout);
    });

    this.socketRef.on("connect_error", (error) => {
      isDevEnvironment && console.log("connect_error: "+error);
      this.socketRef = null;
      this.attemptReconnect();
      clearTimeout(this.reconnectTimeout);
    });

    // Add a new listener for authentication errors
    this.socketRef.on("auth_error", (errorData) => {
      if (this.socketRef) {
        this.socketRef.disconnect(); // Gracefully disconnect the socket
      }
      this.socketRef = null;
      localStorage.removeItem("JMLAuthToken");
      localStorage.removeItem("JMLFirstname");
      localStorage.removeItem("JMLLastname");
      // Here you can redirect to login or show a message to the user
      window.location.href = "/login";
    });

    this.socketRef.on("access_error", (errorData) => {
      if (this.socketRef) {
        this.socketRef.disconnect(); // Gracefully disconnect the socket
      }
      this.socketRef = null;
      localStorage.removeItem("JMLAuthToken");
      localStorage.removeItem("JMLFirstname");
      localStorage.removeItem("JMLLastname");
      // Here you can redirect to login or show a message to the user
      window.location.href = "/login";
    });
  }

  attemptReconnect() {
    if (this.reconnectAttempts > 0) {
      this.reconnectAttempts--;
      this.reconnectTimeout = setTimeout(() => {
        this.connect();
      }, this.reconnectDelay);
    } else {
      localStorage.removeItem("JMLAuthToken");
      localStorage.removeItem("JMLFirstname");
      localStorage.removeItem("JMLLastname");
      window.location.href = "/login";
    }
  }

  send(event, data) {
    const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "dev";
    if (this.socketRef) this.socketRef.emit(event, data);
    else isDevEnvironment && console.log("send: socket not connected");
  }

  close() {
    clearTimeout(this.reconnectTimeout); // Clear any reconnect attempt
    if (this.socketRef) this.socketRef.disconnect();
    else this.socketRef = null;
  }

  attachCustomListeners() {
    if (!this.socketRef) return;
    for (const [type, listeners] of Object.entries(this.customListeners)) {
      listeners.forEach((listener) => {
        this.socketRef.on(type, listener);
      });
    }
  }

  addEventListener(type, listener) {
    if (!this.socketRef) {
      return;
    }
    if (!this.customListeners[type]) {
      this.customListeners[type] = [];
    }
    this.customListeners[type].push(listener);
    this.socketRef.on(type, listener);
  }

  removeEventListener(type, listener) {
    if (!this.socketRef) {
      return;
    }
    if (this.customListeners[type]) {
      const index = this.customListeners[type].indexOf(listener);
      if (index > -1) {
        this.customListeners[type].splice(index, 1);
      }
    }
    this.socketRef.off(type, listener);
  }
}

export default WebSocketInstance;
