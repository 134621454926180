import Card from "react-bootstrap/Card";
import subnetimg from "../../../images/subnet-img.svg";
import vectorArrow from "../../../images/Vector_white.svg";
import Accordion from "react-bootstrap/Accordion";
import downArrow from "../../../images/downVector.svg";
import {removeDuplicates, resourceIcon} from "./Regions";
import "./Vpcs.css";
import { useEffect, useState } from "react";

export const  getAllCategories = (elements) => {
  const categoriesData = {};
  elements.forEach((el) => {
    if (el.data && el.data.category) {
      const category = el.data.category;
      if (!categoriesData[category]) {
        categoriesData[category] = [];
      }
      categoriesData[category].push(el);
    }
  });

  return categoriesData;
}

export const countTotalSubnet = (vpcId, availZones, infraViewResponse) => {
  const subnetArray = [];
  for (let i = 0; i <= availZones.length - 1; i++) {
    const subnetData = getSubnetData(vpcId, availZones[i].id, infraViewResponse);
    if (subnetData.length !== 0) {
      subnetArray.push(subnetData);
    }
  }
  return subnetArray;
};

export const getSubnetData = (vpcId, azId, infraViewResponse) => {
  const findChilds = infraViewResponse.nodes.filter(
    (element) =>
      Array.isArray(element.parent) &&
      element.type === "ec2:subnet" &&
      element.parent.includes(vpcId) &&
      element.parent.includes(azId)
  );

  return findChilds;
};

export const fetchSubnetData = (vpcId, availZones, infraViewResponse) => {
  const subnetArr = [];
  for (let i = 0; i <= availZones.length - 1; i++) {
    const subnetData = getSubnetData(vpcId, availZones[i].id, infraViewResponse);
    subnetArr.push(subnetData);
  }
  subnetArr.forEach((subnet) => {
    addChildrensOfSubnet(subnet, infraViewResponse);
  });
  return subnetArr;
};

export const sliceSubArr = (subnetArr) => {
  if (subnetArr.length > 2) {
    return subnetArr.slice(0, 2);
  } else {
    return subnetArr;
  }
};

export const addChildrensOfSubnet = (parentArr, infraViewResponse) => {
  const childrens = parentArr.map((element) => {
    const child = infraViewResponse.nodes.filter(
      (el) => Array.isArray(el.parent) && el.parent.includes(element.id)
    );
    const categories = getAllCategories(child);
    element.children = { child, categories};
    return element;
  });
  return childrens;
};

const Subnet = ({ searchBar,
    handleResourceIconClick,
     CustomToggle,
     highlightText, allOpen, subnet,
      icons, openAccordionForItem, handleOpenAccordionForItem
    }) => {

  const [activeKey, setActiveKey] = useState({});
  const [openParentCards, setOpenParentCards] = useState({});
  const [openChildCards, setOpenChildCards] = useState({});
  const [openGrandchildCards, setOpenGrandchildCards] = useState({});

  const toggleParentCard = (index) => {
    setOpenParentCards((prev) => {
      const newState = { ...prev };
      newState[index] = !prev[index];
      return newState;
    });
  };

  const toggleChildCard = (id) => {
    setOpenChildCards((prev) => {
      const newState2 = { ...prev };
      newState2[id] = !prev[id];
      return newState2;
    });
  };

  const toggleGrandchildCard = (idx) => {
    setOpenGrandchildCards((prev) => {
      const newState3 = { ...prev };
      newState3[idx] = !prev[idx];
      return newState3;
    });
  };

  const handleToggle = (eventKey) => {
    setActiveKey((prev) => {
      const newState4 = { ...prev };
      newState4[eventKey] = !prev[eventKey];
      return newState4;
    });
  };

  useEffect(() => {
    if (openAccordionForItem) {
      subnet.forEach((sub, index) => {
        handleOpenAccordionForItem(
          openAccordionForItem,
          sub,
          toggleParentCard,
          toggleChildCard,
          toggleGrandchildCard,
          () => {
            handleToggle(index)
          }
        );
      });
    }
  }, [openAccordionForItem]);

  return (
    <Accordion>
      <div className="subnets-data">
        {subnet.map((sub, index) => (
        <Card key={index} className="subnet-expansion">
          <CustomToggle eventKey={index} >
            <Card.Header
            onClick={() => handleToggle(index)}
            className={`subnet-header ${ allOpen
               ?  (!activeKey[index] ? 'no-border-bottom' : '')
               : (activeKey[index] ? 'no-border-bottom' : '')}`}
            style={{
                borderBottomLeftRadius: allOpen
                 ? ( !activeKey[index] ? "0%" : "16px")
                 : (activeKey[index] ? "0%" : "16px"),
                borderBottomRightRadius: allOpen
                 ? ( !activeKey[index] ? "0%" : "16px")
                 : (activeKey[index] ? "0%" : "16px"),
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px"
              }}
            >
              <div className="vpc-subnet">
                <span className="subnet-btn">
                  <img src={subnetimg} alt="subnetimg" />
                  <span className="vpcs-name">{highlightText(sub.name, searchBar)}</span>
                </span>
                <img
                  src={vectorArrow}
                  alt="vectorArrow"
                  className = {allOpen
                    ? (!activeKey[index] ? 'uparrow p-2' : 'p-2')
                    : (activeKey[index] ? 'uparrow p-2' : 'p-2')}
                />
              </div>
            </Card.Header>
          </CustomToggle>
          <Accordion.Collapse
           eventKey={index}
           in={allOpen ? !activeKey[index] : activeKey[index]}
          >
            <Card.Body className="subnet-body">
              {Object.keys(sub?.children?.categories).length === 0 ?
              <div className="no-resource">No resource found in this subnet</div> :
              <Accordion>
                {Object.keys(sub?.children?.categories).filter((category) =>
                  removeDuplicates(sub?.children?.categories[category]).length !== 0)
                  .map((category, index, arr) => {
                    const uniqueItems = removeDuplicates(sub?.children?.categories[category]);
                    const isLastCategory = index === arr.length - 1
                    return (
                      <div key={category}>
                      <Card className={isLastCategory ? "storage-data" : "category-compute-data"}>
                        <CustomToggle eventKey={category}>
                          <Card.Header className="category-header"
                            style={{borderBottomLeftRadius: isLastCategory ? '16px': ""
                              , borderBottomRightRadius: isLastCategory ? '16px': ""
                            }}
                            onClick={() => toggleParentCard(category)}
                          >
                            <span className="category-btn">
                              {icons[category]}
                              <span>{category}</span>
                            </span>
                            <span className="vpc-category-count">
                              <span className="categories-count">
                                {Object.keys(uniqueItems).length}
                              </span>
                              <span>
                                <img
                                  src={downArrow}
                                  alt=""
                                  className = {allOpen
                                    ? (!openParentCards[category] ? 'uparrow' : '')
                                    : (openParentCards[category] ? 'uparrow' : '')}
                                />
                              </span>
                            </span>
                          </Card.Header>
                        </CustomToggle>
                        <Accordion.Collapse
                         eventKey={category}
                         in={allOpen ? !openParentCards[category] : openParentCards[category]}
                        >
                          <Card.Body className={isLastCategory ? "category-sub-body" : "category-body"}>
                            <Accordion>
                              {Object.keys(uniqueItems).map((el, idx) => {
                                  return (
                                    <Card
                                      className="category-details"
                                      key={idx}
                                    >
                                      <CustomToggle eventKey={idx}>
                                        <Card.Header className="category-details-header"
                                          onClick={() => toggleChildCard(el)}
                                        >
                                          <span className="category-btn">
                                            {resourceIcon(el)}
                                            <span className={`body-data ${openAccordionForItem?.resource?.type?.includes(":") && openAccordionForItem?.resource?.type.split(":")[0] === el ? "matched-text" : ""}`}>
                                            {highlightText(el, searchBar)}
                                            </span>
                                          </span>
                                          <span className="compute-btn">
                                            <span className="categories-count">
                                              {Object.keys(uniqueItems[el]).length}
                                            </span>
                                            <img
                                              src={downArrow}
                                              alt=""
                                              className = {allOpen
                                                ? (!openChildCards[el] ? "arrow-up" : "vector-arrow")
                                                : (openChildCards[el] ? "arrow-up" : "vector-arrow")}
                                            />
                                          </span>
                                        </Card.Header>
                                      </CustomToggle>
                                      <Accordion.Collapse
                                        eventKey={idx}
                                        in={allOpen ? !openChildCards[el] : openChildCards[el]}
                                      >
                                        <Card.Body className="category-details-body">
                                          <Accordion>
                                          {Object.keys(uniqueItems[el]).map((element, innerIdx) => (
                                            <Card
                                              className="category-details"
                                              key={innerIdx}
                                            >
                                              <CustomToggle
                                                eventKey={innerIdx}
                                              >
                                                <Card.Header className="category-details-header"
                                                  onClick={() => toggleGrandchildCard(element)}
                                                >
                                                  <span className="compute-btn">
                                                    <span>{resourceIcon(element)}</span>
                                                    <span className={`body-data ${openAccordionForItem?.resource?.type?.includes(":") && openAccordionForItem?.resource?.type.split(":")[1] === element ? "matched-text" : ""}`}>
                                                      {highlightText(element, searchBar)}
                                                    </span>
                                                  </span>
                                                  <span className="compute-btn">
                                                    <span className="categories-count">
                                                      {uniqueItems[el][element].length}
                                                    </span>
                                                    <img
                                                      src={downArrow}
                                                      alt=""
                                                      className = {allOpen
                                                        ? (!openGrandchildCards[element] ? "arrow-up" : "vector-arrow")
                                                        : (openGrandchildCards[element] ? "arrow-up" : "vector-arrow")}
                                                    />
                                                  </span>
                                                </Card.Header>
                                              </CustomToggle>
                                              <Accordion.Collapse
                                                eventKey={innerIdx}
                                                in={allOpen ? !openGrandchildCards[element] : openGrandchildCards[element]}
                                              >
                                                <Card.Body className="category-details-body">
                                                  {uniqueItems[el][element].map((item, itemIdx) => (
                                                    <div key={itemIdx} className="instances"
                                                      onClick={(e) => handleResourceIconClick(e, item)}
                                                    >
                                                      <span>{resourceIcon(item.type?.includes(":") &&
                                                        item.type.split(":")[1])}</span>
                                                      <span className={`body-data ${openAccordionForItem?.resource?.name === item.name ? "matched-text" : ""}`}>{highlightText(item.name.length>10
                                                        ? item.name.slice(0,10)+"..." : item.name, searchBar)}
                                                      </span>
                                                    </div>
                                                  ))}
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </Card>))}
                                          </Accordion>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  );
                                }
                              )}
                            </Accordion>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      </div>
                    );
                  })}
              </Accordion>}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
      </div>
    </Accordion>
  );
};

export default Subnet;

