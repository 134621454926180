import styles from "./Projects.module.css";
import { useState } from "react";
import Copy from "../../../images/copy.svg"
import Correct from "../../../images/correct.svg"

export const ProjectDescription = ({descStyle, description, name}) => {
    const [copied, setCopied] = useState(false);
    const copyTextToClipboard = async (text, projectName) => {
        if(text){
            await navigator.clipboard.writeText(text);
            setCopied(true);
        }else{
            await navigator.clipboard.writeText(projectName);
            setCopied(true);
        }
    }
    return(
        <>
            <div style={descStyle ? { left: '' } : { left: '55%' }} className={styles["project_des"]} onClick={(e) => e.stopPropagation()}>
                {description ? <span>{description}</span> : <span>{name}</span>}
                {copied
                    ?   <span>
                            <button disabled className={styles["correct-img"]}>
                                <img src = {Correct} alt="correct"/>
                            </button>
                        </span>
                    :   <span>
                            <button className = {styles["copy-img"]} onClick={() => copyTextToClipboard(description, name)}>
                                <img src = {Copy} alt="copy"/>
                            </button>
                        </span>
                }
            </div>
        </>
    )
}