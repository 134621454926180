/**
 * SearchModal component for displaying search results and executing commands.
 */
import CommandsMgr from "../canvas_commands";
import { CloudProvider } from "../canvas_commands";
import awsNodeTypes from "../../icons/aws-icons";
import React, { useState, useEffect, useRef } from "react";
import styles from "../view-edit-architecture.module.css";
import { CrossSvg } from "../../../../images/svg/icons";
import Copy from "../../../../images/copy.svg";
import Retry from "../../../../images/retry_img.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useContext } from 'react';
import context from "../../../../store/create-context";

const SearchModal = ({
  items,
  setItems,
  title = "",
  executeCommand,
  setSearchLoader,
  descriptionId,
  setSResponse,
  sResponse,
  socket,
  searchTextRef,
  askAIbuttonClickHandler
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  const [startY, setStartY] = useState(0);
  const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "dev";
  let commandsMgr = new CommandsMgr(
    CloudProvider.AWS,
    descriptionId,
    setSResponse,
    sResponse,
    socket
  );
  const {queryStr,  setQueryStr } = useContext(context);
  const askAiResponseRef = useRef(null);

  useEffect(() => {
    // Define the function for handling keydown events
    const handleKeyDown = (event) => {
      if (event.key === "ArrowUp") {
        setHoveredIndex((prevState) => (prevState > 0 ? prevState - 1 : 0));
      } else if (event.key === "ArrowDown") {
        setHoveredIndex((prevState) =>
          prevState < items.length - 1 ? prevState + 1 : items.length - 1
        );
      } else if (event.key === "Enter") { // This most likely isn't working as of Nov 21, 2024.
        event.preventDefault();
        if (searchTextRef.current && searchTextRef.current.value !== "") {
          askAIbuttonClickHandler();
        }
      }
    };

    // Add the event listener to the window
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup: remove the event listener
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const executeCommands = (commandsStr) => {
    // Split the commands string into an array of commands
    const commands = commandsStr.split("\n");
    isDevEnvironment && console.log(commands);
    let newNodes = [],
      newNode = null;
    let newEdges = [],
      newEdge = null;
    // Loop over the commands and call executeCommand for each of them
    for (const command of commands) {
      [newNode, newEdge] = commandsMgr.execute(command, setSearchLoader, false);
      // if newNode is not null and newNode is not in awsNodeTypes
      if (newNode && !awsNodeTypes[newNode.type]) {
        isDevEnvironment && console.log("newNode is not null and newNode is not in awsNodeTypes");
        isDevEnvironment && console.log(newNode);
        continue;
      }
      if (newNode) {
        newNodes.push(newNode);
      }
      if (newEdge) {
        newEdges.push(newEdge);
      }
    }
    // Add the new nodes and edges to the graph
    commandsMgr.updateCanvas(newNodes, newEdges);
    setItems([]);
  };

  const handleCopyAskAIResponse = async (text) =>{
    try {
      await navigator.clipboard.writeText(text.summary + ": " + text.description);
    } catch (err) {
      console.error("Failed to copy", err);
    }
  };

  const handleCopyAllAskAIResponse = async (items) =>{
    try {
      // Create a newline separated string of all the texts
      const copiedText = items.map(item => `${item.summary}:${item.description}`).join("\n");
      await navigator.clipboard.writeText(copiedText);
    } catch (err) {
      console.error("Failed to copy", err);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    if (askAiResponseRef.current) {
      setInitialHeight(askAiResponseRef.current.getBoundingClientRect().height);
      setStartY(e.clientY);
   }
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (askAiResponseRef.current) {
      const deltaY = startY - e.clientY;
      const newHeight = initialHeight + deltaY;
      askAiResponseRef.current.style.height = `${newHeight}px`;
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleClick = async (text) =>{
    try {
      setTimeout(() => {
        if (searchTextRef.current) {
          searchTextRef.current.focus();
          searchTextRef.current.setSelectionRange(0, 0);
        }
      }, 0);
      setQueryStr("Give more info on- " + text.summary + ": " + text.description);
    } catch (err) {
      console.error("Failed to copy", err);
    }
  };

  useEffect(() => {
    if (queryStr) {
      askAIbuttonClickHandler();
    }
  }, [queryStr])

  return (
    <>
    <div className={styles['resize-handle']} onMouseDown={handleMouseDown}>
      <div
        className={styles['close-button']}
      >
        <span onClick={() => setItems([])} style={{position:'absolute'}}><CrossSvg/></span>
      </div>
    </div>
    <div className={styles["search-ask-ai-res-modal"]}>
      {items.length > 0 && (
        <div ref={askAiResponseRef} className={styles["resize-ask-ai-res-modal"]}>
          <div
            className={styles['search-modal-class-content']}
          >
            {items?.map((item, index) => (
              <div
                className="d-flex flex-row"
                style={{
                  marginBottom: "1.0rem",
                }}
                key={index}
                // onClick={() => executeCommands(item.commands)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="button-tooltip">Click to get more info</Tooltip>}>
                  <div className={styles['search-modal-item']} style={{opacity: index === hoveredIndex ? 1 : 0.5,}}
                     onClick={() => handleClick(item)}>
                    {item.summary && (<> {capitalizeWords(item.summary)}:</>)} &nbsp;
                      <span className="">{item.description}</span>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="button-tooltip">Copy</Tooltip>}>
                  <div className={styles['response-copy']} onClick={() => handleCopyAskAIResponse(item)}><img src = {Copy} alt="copy"/></div>
                </OverlayTrigger>
              </div>
            ))}
            <div className={styles["message-container"]}>
              <div className={styles["warning-message"]}>
                This response is generated using AI and may contain inaccuracies. 
                Please verify independently & consult a human expert for critical decisions.
              </div>
              <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="button-tooltip">Copy All</Tooltip>}>
                  <div className={styles['response-copy']} onClick={() => handleCopyAllAskAIResponse(items)}><img src = {Copy} alt="copy_all"/></div>
              </OverlayTrigger>
              <span className={styles["res-try-again"]}>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="button-tooltip">Retry</Tooltip>}
                >
                  <button onClick={askAIbuttonClickHandler}>
                    <img src={Retry} alt="retry_img" style={{width:'20px', filter:'invert(1)', opacity:'0.6'}}/>
                  </button>
                </OverlayTrigger>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export default SearchModal;
