import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./InfraServicesModal.css";
import { infraviewResourceIcon } from "../new-icons/infraviewResource-icons";
import generic_application from "../../../images/aws/Resource-Icons/Res_General-Icons/Res_48_Dark/Res_Generic-Application_48_Dark.svg";
import { Spinner } from 'react-bootstrap';

export const InfraServicesModal = (props) => {
  const { show, onHide, infraServices, loading, selectedDropDownValues} = props;
  const { supported_services = [], unsupported_services = [] } = infraServices || {};

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className='res-header'>
          Service Coverage for {selectedDropDownValues["aws:account"]?.id} in {selectedDropDownValues["aws:region"]?.name}
        </Modal.Header>
        <Modal.Body className='res-body'>
          {loading ? (
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <Spinner  animation="grow" size="sm" />
              <span style={{ marginLeft: "5px" }}>Request being processed...</span>
            </div>
            ) : (
            <>
              <h5 className="subtitle"><u>Services supported by JigsawML, including the ones you are using</u></h5>
              <div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto", paddingTop: "10px", paddingBottom: "10px" }}>
                {supported_services.length > 0 ? (
                  supported_services.map((el, index) => (
                    <div key={index} className="res-item">
                      {infraviewResourceIcon[el] ? (
                        <span>{infraviewResourceIcon[el]}</span>
                      ) : (
                        <img src={generic_application} alt="Generic Application" style={{width:'24px'}}/>
                      )}
                      <span>{el}</span>
                    </div>
                  ))
                ) : (
                  <div>At this time, we couldn't find any supported services linked to your resources in this region.</div>
                )}
              </div>

              <h5 className="subtitle"><u>Services you are using but not yet supported by JigsawML</u></h5>
              <div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto", paddingTop: "10px", paddingBottom: "10px" }}>
                {unsupported_services.length > 0 ? (
                  unsupported_services.map((el, index) => (
                    <div key={index} className="res-item unsupported-service">
                      {infraviewResourceIcon[el] ? (
                        <span>{infraviewResourceIcon[el]}</span>
                      ) : (
                        <img src={generic_application} alt="Generic Application" style={{width:'24px'}}/>
                      )}
                      <span>{el}</span>
                    </div>
                  ))
                ) : (
                  <div>We're pleased to inform you that we cover all services related to your resources in this region. If you notice any discrepancies, it may be because you don't have resources in this region, your resources are not currently supported by JigsawML, or this is an opt-in region.</div>
                )}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className='res-footer'>
          <div className='services-message'>
            More services and resource types are continually added and will be supported soon! If there's a service or resource type you'd like to see available sooner, we'd love to hear from you. Additionally, if you encounter any errors on our end, please don't hesitate to reach out. To ensure the list of services not yet supported is readable and meaningful , we only display those services for which you may have one or more resource types in this region. Feel free to reach out to us at info@jigsawml.com.
          </div>
          <Button className='footer-button' onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
