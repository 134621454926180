export const trackLogin = (user_id) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "login",
    userId: user_id
  });
};

export const trackNodeEdgeClick = (user_id, clickedOn) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "track-node-edge-click",
    userId: user_id,
    ClickedOn: clickedOn
  });
};

export const timeSpent = (time, componentName, user_id) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "timespent",
    timespent: time,
    component: componentName,
    userId: user_id
  });
};

export const handleZoomIn = (user_id) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "zoomEvent",
    zoomAction: 'Zoom In',
    userId: user_id
  });
}

export const handleZoomOut = (user_id) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "zoomEvent",
    zoomAction: 'Zoom Out',
    userId: user_id
  });
}

export const handleDoubleClickOnParentNode = (user_id) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "dblClickOnNode",
    userId: user_id
  });
}

export const handleClickOnAskAi = (user_id, canvasView) => {
  const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "dev";
  isDevEnvironment && console.log("handleClickOnAskAi method is call", {user_id});
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "searchCountAskAI",
    userId: user_id,
    canvasView: canvasView,   // current view open when "Ask AI" is clicked
    url: window.location.href
  });
}