import Carousel from "react-bootstrap/Carousel";
import code_view from "../../../../../images/code_view.svg";
import index1 from "../../../../../images/index-1.svg";
import services from "../../../../../images/services.svg";
import index2 from "../../../../../images/index-2.svg";
import index3 from "../../../../../images/index-3.svg";
import index4 from "../../../../../images/index-4.svg";
import infraView from "../../../../../images/infra_view.svg";
import AskAi from "../../../../../images/ask_ai_response.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import './ProductCarousel.css';

const slides = [
  {
    image: code_view,
    title: "Instant clarity in just a few minutes",
    description: [
      "Seamlessly integrate with your code repositories and cloud accounts.",
      "Generate comprehensive map of your cloud architecture and associated resources.",
      "Enhanced with semantic details, this map provides a clearer understanding of your system",
    ],
    footerImage: index1,
  },
  {
    title: "",
    image: services,
    description: [
      "Note that these are not simple architecture diagrams.",
      "These visualizations incorporate additional information that is only possible through intelligent analysis, understanding, and interpretation.",
      "This reduces the collective cognitive burden that cloud teams face today.",
    ],
    footerImage: index2,
  },
  {
    image: infraView,
    title: "Real-Time Resource Scanning",
    description: [
      "Scan your cloud accounts to identify every resource your organization uses.",
      "For cloud teams, this comprehensive inventory means no more scrambling to find what resources are deployed or where things are located.",
      "JigsawML connects all the dots, letting you see which code is linked to which cloud component and understand how everything fits together in real-time.",
    ],
    footerImage: index3,
  },
  {
    title: "Enterprise Grade AI At Your Disposal",
    image: AskAi,
    description: ["Ask intelligent questions about your infrastructure."],
    footerImage: index4,
  },
];

const ProductCarousel = () => {
  return (
    <div className="product-carousel-container">
        <Carousel className="vertical" indicators={false} controls={false}>
            {slides.map((slide, index) => (
            <Carousel.Item key={index}>
                <div className="product-carousel-item">
                    <span className="product-content" style={{flexDirection: index % 2 === 0 ? "row" : "row-reverse"}} >
                        <div className="product-text-content">
                        {slide.title && <strong className="product-img-title">{slide.title}</strong>}
                        {slide.description.map((text, i) => (
                            <p key={i} className="product-detail">{text}</p>
                        ))}
                        </div>
                        <div className="product-image-content">
                        <img
                            className="d-block w-100 product-img"
                            src={slide.image}
                            alt={`Slide ${index + 1}`}
                        />
                        </div>
                    </span>
                    <img
                        src={slide.footerImage}
                        alt="Footer decoration"
                        className="product-footer-image"
                    />
                </div>
            </Carousel.Item>
            ))}
        </Carousel>
    </div>
  );
};

export default ProductCarousel;
