// api.js
import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_SIMPLIFAI_BACKEND_API_URL}`, // Replace with your backend API base URL
});

const event = new Event('unauthorized');
const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "dev";

// Request interceptor for adding the Authorization header
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('JMLAuthToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  isDevEnvironment && console.log(config);
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Response interceptor for handling 401 Unauthorized responses
api.interceptors.response.use(
  response => response,
  error => {
    const status = error.response ? error.response.status : null;

    if (status === 401) {
      // Token is invalid or expired, redirect to login page
      localStorage.removeItem('JMLAuthToken');
      localStorage.removeItem('JMLFirstname');
      localStorage.removeItem('JMLLastname');
      
      // Redirect to login, replace this with your routing logic
      // For example, you could use react-router's useHistory hook
      window.dispatchEvent(event);
    }

    return Promise.reject(error);
  }
);

export default api;