import "bootstrap/dist/css/bootstrap.min.css";
import './InfraScannerCarousel.css';
import Carousel from "react-bootstrap/Carousel";
import infra_view from "../../../../../images/infra_view.png";
import index1 from "../../../../../images/index-1.svg";
import actionable_insights from "../../../../../images/actionable_insights.png";
import infra_costs from "../../../../../images/infra_costs.png";
import index2 from "../../../../../images/index-2.svg";
import index3 from "../../../../../images/index-3.svg";
import index4 from "../../../../../images/index-4.svg";
import AskAi from "../../../../../images/ask_ai_response.svg";
import Infraview_askAI from "../../../../../images/Infraview_AskAI.png";
import detailed_report_generate from "../../../../../images/generate_detailed_report.png";
import list_of_services_used from "../../../../../images/list_of_services_used.png";

const slides = [
  {
    image: infra_view,
    title: "Comprehensive Visibility",
    description: [
      "Easily scan your AWS services to ensure every part of your infrastructure is accounted for, uncover hidden resources, and eliminate blind spots.",
    ],
    footerImage: index1,
  },
  {
    image: actionable_insights,
    title: "Actionable Insights in Seconds!",
    description: [
      "Get a complete view of your cloud resources in under a minute, saving valuable time for high-priority tasks.",
    ],
    footerImage: index2,
  },
  {
    image: infra_costs,
    title: "Effortless Cost Control",
    description: [
      "Quickly identify costly services and underutilized regions to reduce expenses and improve resource efficiency.",
    ],
    footerImage: index3,
  },
  {
    image: list_of_services_used,
    title: "See everything!",
    description: [
      "We will tell you all the cloud services you are using even if we don't support them completely (just yet).",
    ],
    footerImage: index4,
  },
  {
    image: detailed_report_generate,
    title: "Detailed Reporting Made Easy",
    description: [
      "Generate comprehensive cloud inventory PDFs for seamless reference and reporting."
    ],
    footerImage: index4,
  },
];

const InfraScannerCarousel = () => {
  return (
    <div className="product-carousel-container">
        <Carousel interval={5000} className="vertical" indicators={false} controls={false}>
            {slides.map((slide, index) => (
            <Carousel.Item key={index}>
                <div className="product-carousel-item">
                    <span className="product-content" style={{flexDirection: index % 2 === 0 ? "row" : "row-reverse"}} >
                        <div className="product-text-content">
                        {slide.title && <strong className="product-img-title">{slide.title}</strong>}
                        {slide.description.map((text, i) => (
                            <p key={i} className="product-detail">{text}</p>
                        ))}
                        </div>
                        <div className="product-image-content">
                        <img
                            className="d-block w-100 product-img"
                            src={slide.image}
                            alt={`Slide ${index + 1}`}
                        />
                        </div>
                    </span>
                    <img
                        src={slide.footerImage}
                        alt="Footer decoration"
                        className="product-footer-image"
                    />
                </div>
            </Carousel.Item>
            ))}
        </Carousel>
    </div>
  );
};

export default InfraScannerCarousel;
