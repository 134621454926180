import "../components/details.css";
import "./Blogs.css"
import Footer from '../../../NewLanding2/Footer';
import RightSidebar from '../InPress/rightSidebar';
import codeView from '../../../../assets/code_view.png';
import cloudx_trophy from '../../../../assets/cloudX_trophy.jpeg';
import whiteboard_image_2 from '../../../../assets/whiteboard_image_2.jpeg';
import whiteboard_image_3 from '../../../../assets/whiteboard_image_3.jpeg';
import aws_architecture from '../../../../assets/aws_architecture.png';
import Infra_view_2 from '../../../../assets/Infra_view_2.png';
import Navbar from '../../../NewLanding2/Navbar';

const Blogs = () => {
  return (
    <div>
      <div style={{ backgroundColor: '#e9edf5' }}>
        <Navbar/>
      </div>
      <div className="press-blog-index-container">
        <div className="left-column">
          <span className="blog-title">JigsawML Emerges from Stealth with Award-Winning AI Platform to 
            Revolutionize Cloud Operations</span>
            <div className="blog-title-description">
              <div className="resource-description" style={{ textAlign: 'justify'}}>
              <p className="equal-words">We’re thrilled to step into the spotlight and officially launch JigsawML’s 
                cutting-edge AI-powered Cloud Intelligence Platform.</p>
              <p className="equal-words">Designed to revolutionize how enterprises manage complex cloud environments, 
                JigsawML’s debut has already made waves, earning the prestigious 2024 CloudX Award for Cloud Management 
                at this year’s DeveloperWeek CloudX Conference in Santa Clara.</p>
              <p><center><img src={cloudx_trophy} alt='cloudx_trophy' className="responsive-image-small"/></center></p>
              <p className="equal-words">Managing cloud operations today is like solving a complex, constantly changing 
              puzzle where spiraling costs, scarce manpower and security must be simultaneously optimized. For many 
              cloud teams, keeping track of what’s where, why it’s there, and how it all connects feels 
              overwhelming—especially as software and infrastructure scale up and team members rotate. But what if 
              there were a better way to understand and manage this complex landscape?</p>

              <span className="sub-title">The Cloud Documentation Struggle</span>
              <p>Most often our architecture diagrams look like these:
                <div>
                  <img src={whiteboard_image_2} alt="whiteboard_image" style={{ width: '40%' }}/>
                  <img src={whiteboard_image_3} alt="whiteboard_image" style={{ width: '60%' }}/>
                  <center><em>(left one if you are lucky, right one if you are not)</em></center>
                  </div>
              </p>
              <p className="equal-words">Ask any cloud team about their architecture documentation, and you’ll likely 
                get a sigh. Most organizations rely on static diagrams that are often outdated as soon as they’re 
                created. Imagine that you’ve painstakingly mapped out your cloud infrastructure - only to find that, 
                just a few weeks later, it no longer reflects reality. The engineers who crafted it might have moved 
                on, and now everyone else is left with a rough sketch rather than a living, breathing map.
              </p>
              <p className="equal-words">This lack of up-to-date documentation becomes a major barrier. It’s hard for 
                new engineers to get up to speed, and cloud architects spend hours trying to align what’s in the 
                diagrams with what’s actually running. It’s inefficient, frustrating, and a common story across cloud 
                teams everywhere.
              </p>
              <p>Forget the above - even the well-managed diagrams such as those from cloud hyperscalers look like this:
                <div className="product-image">
                  <center><img src={aws_architecture} alt="aws_architecture" className="responsive-image-medium"/></center></div></p>

              <span className="sub-title">Enter JigsawML: Automatic and Interactive Cloud Architecture Visualization</span>
              <p className="equal-words">This is where JigsawML comes in. It automatically generates an interactive 
                visualization of your cloud software architecture, and it does this by connecting directly to your 
                code repositories and cloud accounts. The result is a live, evolving map of your cloud setup that 
                offers both a high-level overview and the option to zoom in on any detail. No more static diagrams; now 
                your architecture diagram lives and grows with your system.
              </p>
              <p className="equal-words"><strong>Note that these are not simple architecture diagrams. These 
                visualizations incorporate additional information that is only possible through some <em>intelligent
                  </em> analysis, understanding, and interpretation. The aim is to reduce the collective cognitive burden 
                  that cloud teams face today.
                </strong></p>
              <p className="equal-words">Imagine being able to explore your cloud architecture like navigating a city 
                map. You can zoom out to see the entire system or dive into individual “neighborhoods” or components. 
                Engineers, DevOps, FinOps, and other team members can all use this interactive map, each seeing exactly 
                what they need in order to make faster, more informed decisions.
              </p>

              <span className="sub-title">From Code to Cloud: Clear Guidance on Software Components</span>
              <p className="equal-words">One of the most exciting parts of JigsawML is its ability to offer practical 
                guidance. It not only shows your architecture but helps you understand each component—how it was 
                created, where it lives in the code, and how it’s deployed in the cloud. Whether you’re onboarding new 
                engineers or managing transitions, this clarity means you’re not left guessing about the inner workings 
                of your software stack.</p>
              <p className="equal-words">For cloud teams juggling multiple priorities, JigsawML provides a much-needed 
                lifeline, simplifying operations and enabling teams to work together with a common understanding.</p>
              <p><center><img src={codeView} alt='codeView' className="responsive-image-large"/></center></p>

              <span className="sub-title">Seeing the Full Picture: Real-Time Resource Scanning</span>
              <p className="equal-words">Beyond visualizing the architecture, JigsawML scans your cloud accounts to 
                identify every resource your organization uses. For cloud teams, this comprehensive inventory means no 
                more scrambling to find what resources are deployed or where things are located. JigsawML connects all 
                the dots, letting you see which code is linked to which cloud component and understand how everything 
                fits together in real-time.
              </p>
              <p className="equal-words">This level of clarity is especially useful for troubleshooting and for 
                managing cloud resources more efficiently. Imagine a world where, instead of hunting for answers across 
                tools and docs, you have a single source of truth that lets you see what’s running, where, and why.
              </p>
              <p><div style={{display:'flex', flexDirection:'column', maxWidth:'885px'}}>
                <center> <img src={Infra_view_2} alt='Infra view with Ask AI' className="responsive-image-small"/></center></div></p>

              <span className="sub-title">A Platform Built for Today’s Cloud Teams</span>
              <p className="equal-words">As we navigate the transformative age of AI, design must not only keep pace 
                but set new standards. At JigsawML, we’ve redefined what a platform should look like by building from 
                first principles to fundamentally simplify cloud management. We asked ourselves, <em>“If Steve Jobs & 
                Jony Ive were to design a platform for cloud practitioners, what would it look like?”</em> The result is a 
                groundbreaking solution that seamlessly integrates cutting-edge AI to meet teams exactly where they 
                are. It goes far beyond traditional interfaces, minimizing the learning curve and maximizing 
                productivity.</p>
              <p className="equal-words">If you are facing massive productivity challenges with how your cloud is managed, 
                please connect with us either by signing up for the Beta or emailing us at {" "} 
                <a href="mailto:info@jigsawml.com">info@jigsawml.com</a>.</p>
              </div>
            </div>
        </div>
        <div className="right-sidebar">
        <RightSidebar />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blogs;
