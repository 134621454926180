import awsNodeTypes from "./aws-icons";
import azureNodeTypes from "./azure-icons";

const allNodeTypes = {
  ...awsNodeTypes,
  ...azureNodeTypes,
  group: GroupNode,
};

export default allNodeTypes;

function GroupNode({ data }) {

  return (
    <>
      <div>
        <label htmlFor="text" className="position-absolute " style={{left:'50%',top:'0%'}}>{data.label ?? ""}</label>
      </div>
    </>
  );
}
